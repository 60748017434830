
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "School schedules are part of the learning environment – and influence outcomes";
export const lead = "The organization of school calendars – from distribution of instruction days across weeks and years to duration and organization of the school  day itself – can have important consequences for  the quality and equity of education systems.";
export const layout = "focus";
export const article = "quality";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="School schedules are part of the learning environment – and influence outcomes" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=272" mdxType="FocusDetails">
      <h1 {...{
        "id": "school-schedules-are-part-of-the-learning-environment--and-influence-outcomes"
      }}>{`School schedules are part of the learning environment – and influence outcomes`}</h1>
      <p>{`The organization of school calendars – from distribution of instruction days across weeks and years to duration and organization of the school day itself – can have important consequences for the quality and equity of education systems.`}</p>
      <h2 {...{
        "id": "school-years-are-organized-in-a-variety-of-ways"
      }}>{`School years are organized in a variety of ways`}</h2>
      <p>{`The organization of yearly school calendars is directly linked to total intended instruction time for students, often legislated by national or local authorities. Variation across countries is wide: Among middle- and high-income countries, the average number of instruction days per year in primary education ranges from 162 in France to 219 in Israel (European Commission/EACEA/Eurydice, 2020; OECD, 2019).`}</p>
      <p>{`Part of the cross-country difference is due to the organization of the school week, which varies, for example, from 4 or 4.5 days in France to 6 days in Israel (AMF, 2018; Kadari-Ovadia, 2020). Another factor is the total length of school breaks, from less than 9 weeks in Mexico to nearly 18 in Ireland. There are also differences in the distribution of school breaks through the year. Although students in Luxembourg and Türkiye have 15 weeks of vacation a year, it is split into six breaks in Luxembourg but two in Türkiye.`}</p>
      <FocusImage size="large" src={"3-School_calendar_1.png"} alt="Countries vary widely in school break duration and organization" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=345" mdxType="FocusImage" />
      <p>{`A common pattern is for the longest school break to be at the end of the academic year, often during the summer. The history of this calendar, traditional in Europe and North America and now common across much of the world, is less likely linked to agrarian labour needs, as commonly believed, and more likely associated with a need for standardization as urbanization and income levels increased (Fischel, 2006; Melker and Weber, 2014)`}</p>
      <p>{`Many countries’ school calendar’s structure is due more to the influence of colonialism than seasons. The Bangladesh school calendar dates to the British colonial period and is not aligned with local agricultural cycles, requiring students to take exams during the peak wet-season harvest period (Ito and Shonchoy, 2020). Schools in Somalia run from September to June, likely an influence of British and Italian rule, unaligned with the country’s warmer months and the school year in neighbouring countries, such as Kenya and Uganda (Kenya Ministry of Education, 2018; Uganda Ministry of Education and Sports, 2018; Wickman, 2011). Schools in southern hemisphere territories, such as American Samoa and French Polynesia, follow the northern hemisphere calendar, unlike most of their neighbours (Fischel, 2006).`}</p>
      <p>{`School calendars are also influenced by cultural and religious practices. It is common for countries with a Christian background to have institutionalized breaks around Christmas and Easter, even if they are increasingly referred to as winter and spring breaks (Burke and Segall, 2011). In Muslim countries, school calendars may change every year to adapt to the moving Ramadan dates (Ito and Shonchoy, 2020). Indigenous schools in Canada, Peru and the United States use ancestral calendars that may take into account moon and seasonal cycles or cultural practices (Huaman, 2020).`}</p>
      <p>{`School calendars may even differ within a given country. Breaks may vary among subnational units, due either to autonomous decisions or to purposeful staggering by the central authority (European Commission/EACEA/Eurydice, 2020). Staggered calendars within a single school can allow groups of students go on vacation at different times. Adaptation of school calendars to local contexts can have important equity implications. Using a natural experiment in calendar shifts, a study showed that the overlap between seasonal labour demand and annual school examinations in Bangladesh led to a dropout rate seven percentage points higher for students from agricultural households. In India, the difference was estimated to be from five to seven percentage points. During peak harvest seasons, not only were students from agricultural households more likely to miss class, but also fatigue and injuries from field work hindered exam preparation (Ito and Shonchoy, 2020). School calendars’ lack of resonance with local cultures has contributed to higher teacher absenteeism and lower attendance rates among children from Scheduled Tribes in India (Brahmanandam and Bosu Babu, 2016; Sujatha, 2002).`}</p>
      <p>{`Some argue school calendars may also influence learning outcomes, especially for poorer students. The idea of the ‘summer slide’, a drop in achievement due to the long period away from classes, has led several schools to adopt year-round education that distributes school days more evenly throughout the year. Insufficient evidence makes it difficult to assess the impact of this change on overall performance, and some have questioned the magnitude of summer learning loss compared with loss distributed across the shorter breaks (Finnie et al., 2019; von Hippel and Hamrock, 2019).`}</p>
      <h2 {...{
        "id": "the-organization-of-the-school-day-also-matters"
      }}>{`The organization of the school day also matters`}</h2>
      <p>{`Along with the number of school days, their length determines learners’ total instruction time. Again, wide variation exists within and between countries – from an average of fewer than 600 hours of compulsory instruction per year in primary education in Latvia and the Russian Federation to over 1,000 hours in Chile, Costa Rica and Denmark (OECD, 2019). Differences across countries have likely been exacerbated by the COVID-19 pandemic. Students in many countries have received fewer instruction hours due to staggering of classes to reduce class size. In England (United Kingdom), by contrast, the government will extend the secondary-level school day to help make up for lost time (Hazell, 2021).`}</p>
      <p>{`More instruction time is broadly associated with better student performance, but the effect tends to be mediated by factors such as instruction quality, classroom environment, school autonomy and accountability (Lavy, 2015; Woessmann, 2016; Yeşil Dağlı, 2019). It also depends on what ‘more’ means. Adding a few extra minutes may not make a difference; adding an hour may help; adding three may be counterproductive (Barshay, 2021). If well-used, more instruction time can help foster equity. Across a subset of PISA countries, more instruction time was associated with a greater likelihood of disadvantaged students succeeding academically (Agasisti et al., 2021). More and longer school days are also associated with increased participation of mothers in the labour market (Duchini and Effenterre, 2017; Shure, 2019).`}</p>
      <p>{`Over time, there has been a global shift towards providing full-day education. Although double-shifting, where schools take in different groups of students for morning and afternoon sessions, remains common in Latin America and Africa, many countries, including Chile and Ghana, have moved to phase it out or abolish it (Ashong-Katai, 2013; Parente, 2020). In Namibia, the government’s plans to phase out double shifting were hampered by COVID-19, which pushed many reopening schools back to it (Nakale, 2020; Rasmeni, 2017).`}</p>
      <p>{`Increasing the length of the school day increases costs. In addition to requiring more school buildings, more teachers and longer work contracts, there may be significant infrastructure implications for existing schools. In Germany, primary schools traditionally offered classes only in the morning and so did not need to provide meals. As the country undertook nationwide reform to increase school days’ duration over the past 15 years, many schools had to build cafeterias. Those that could not do so were often unable to change their schedules (Shure, 2019).`}</p>
      <p>{`Beyond duration, school starting times also matter. A growing body of literature points to benefits from delaying starting times, particularly at the secondary level. In addition to allowing more sleep time, later starts appear to align better with adolescents’ circadian rhythm, with peak alertness in the late morning and evening (Kelley et al., 2015; Kirby et al., 2011). A study using students’ random assignments to earlier or later classes in the United States found that delaying the starting time by 50 minutes led to a significant improvement in student performance for all courses, not just the first period (Carrell et al., 2011). Some studies found even short delays helpful. In Hong Kong, China, a 15-minute delay from 7:45am to 8am was associated with greater attentiveness, fewer behavioural difficulties and better peer relationships between secondary school students (Chan et al., 2017). Others support pushing starting time much later. In the United Kingdom, a change from 8:50am to 10am was associated with fewer absences due to illness and improved academic performance (Kelley et al., 2017).`}</p>
      <p>{`Finally, the organization of the school day is not only about instruction time. Recess has been shown to improve students’ level of physical activity, memory and concentration, as well as their socioemotional development and academic performance (Haapala et al., 2017; Zavacky and Michael, 2017). Some countries consider breaks to be part of compulsory instruction time. Denmark and some Spanish regions and autonomous communities regulate recess time by law (OECD, 2019). Still, as daily recess is often seen as a waste of time, many schools do not offer it. In the United States, in the first five years after the 2001 No Child Left Behind Act, which focused on standardized testing, most districts increased time for tested subjects and 20% of districts decreased recess time by an average of 50 minutes per week (McMurrer, 2007). More recently, recess suffered another blow with COVID-19 as reopening schools tried to make up for lost time and found it hard to maintain physical distancing during free time. In Angola, the modified scheduled for the return of primary classes during the pandemic excluded recess (ANGOP, 2021).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=571"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;