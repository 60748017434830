
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Mathematics anxiety negatively affects mathematics performance";
export const lead = "Mathematics anxiety and low mathematics performance are closely linked and potentially exacerbate each other";
export const layout = "focus";
export const article = "learning";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Mathematics anxiety negatively affects mathematics performance" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=183" mdxType="FocusDetails">
      <h1 {...{
        "id": "mathematics-anxiety-negatively-affects-mathematics-performance"
      }}>{`Mathematics anxiety negatively affects mathematics performance`}</h1>
      <p>{`Among a range of barriers to student engagement and success in mathematics is ‘mathematics anxiety’, which is defined as ‘anxiety that interferes with manipulation of numbers and the solving of mathematical problems in a wide variety of ordinary life and academic situations’ (Richardson and Suinn, 1972). Mathematics anxiety not only deters students from participating in mathematics-related academic pursuits and career paths in science, technology, engineering and mathematics (Choe et al., 2019), it also has broader implications for mental health and overall well-being across all age groups (Mammarella et al., 2019).
Over the past decades, there has been more interest in understanding when and how mathematics anxiety develops, as well as more attempts to measure it. Research indicates that the first signs of mathematics anxiety can appear as early as age 6 (Aarnos and Perkkilä, 2012), with significant implications for later development, as anxiety tends to remain stable or increase over time (Cargnelutti et al., 2017; Pekrun, 2016). Despite the possible early onset of this condition, research on primary school children remains limited due to challenges in accurately assessing mathematics anxiety in this age group (Cipora et al., 2018).
Since 2003, PISA has attempted to evaluate mathematics anxiety among 15-year-old students. PISA measures mathematics anxiety by asking students to respond to six statements with the levels of agreement ‘strongly disagree,’ ‘disagree,’ ‘agree’ or ‘strongly agree’. The statements are: ‘I often worry that it will be difficult for me in mathematics classes’; ‘I worry that I will get poor marks in mathematics’; ‘I get very tense when I have to do mathematics homework’; ‘I get very nervous doing mathematics problems’; ‘I feel helpless when doing a mathematics problem’; and ‘I feel anxious about failing in mathematics.’ The responses are aggregated to form the PISA index of mathematics anxiety, providing a standardized measure to compare anxiety levels across different countries and over time (OECD, 2004, 2013, 2023a).
The index of mathematics anxiety increased in a statistically significant way in 37 countries and economies between 2012 and 2022, most notably in Austria, Denmark, Germany, Iceland, Lithuania, Poland, Slovakia, Sweden, Türkiye, the United States and Viet Nam. In Poland and Sweden, for example, 15-year-old students in 2022 were at least 10 percentage points more likely to report getting very tense when doing mathematics problems and mathematics homework than their peers did in 2012. In contrast, anxiety towards mathematics has decreased significantly in Albania, France, Hungary, Jordan, the Republic of Korea, Qatar, Romania and the United Arab Emirates (OECD, 2013, 2023a).`}</p>
      <FocusImage size="small" src={"28-mathanxiety.png"} alt="Students’ mathematics anxiety levels have risen" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=185" mdxType="FocusImage" />
      <p>{`In most countries, the gender differences in levels of mathematics anxiety are wide (OECD, 2023a; UNESCO, 2024). In particular, Denmark, France, Germany, Switzerland and the United Kingdom, which have above-average mean scores in mathematics and a low mathematics anxiety index after accounting for students’ and schools’ socioeconomic profiles, are also among the 10 countries that reported the largest absolute gender gaps in the mathematics anxiety index. During the COVID-19 school closures, boys reported significantly less anxiety towards mathematics if their schools provided adequate support to maintain learning and well-being (OECD, 2023b).
Differences in mathematics anxiety related to socioeconomic status are less pronounced than gender differences but nonetheless exist. Notably, during periods of COVID-19 school closures, the majority of students with a higher socioeconomic status report markedly reduced anxiety towards mathematics compared to their disadvantaged peers. This trend was particularly pronounced in Hungary, Jamaica and Ukraine. However, this pattern is not observed in all education systems (OECD, 2023b).
Mathematics anxiety and low mathematics performance are closely linked and potentially exacerbate each other (Barroso et al., 2021; Carey et al., 2016; Young et al., 2012). PISA results underscore this cyclical relationship by showing that countries where students report high levels of mathematics anxiety also tend to have lower mathematics performance, regardless of individual or school characteristics (OECD, 2004, 2013, 2023a).
However, even among high-performing countries, levels of mathematics anxiety can vary widely. For instance, Japan ranks among the top five in mathematics performance in the 2022 PISA but also reports high levels of mathematics anxiety (OECD, 2023a). This suggests that a variety of complex and nuanced factors contribute to the development of mathematics anxiety beyond just academic performance.
One such factor is negative self-perception. Findings from the 2022 PISA highlight that, on average, more than 6 of every 10 students express concerns about their ability to succeed in mathematics classes (OECD, 2023a). In contrast, students with a growth mindset, characterized by the belief in improving abilities and intelligence through effort and practice, typically have lower mathematics anxiety levels compared to peers who think that their abilities are fixed. Moreover, the impact of mindset orientation extends beyond the mere alleviation of mathematics anxiety. In 46 of 73 surveyed countries and economies, students showing a growth mindset perform better in mathematics, despite experiencing high mathematics anxiety levels. This underscores the resilience and adaptive capabilities fostered by a growth mindset (OECD, 2023a).
Another factor is a lack of support systems, such as supporting teachers, a supportive school environment and family involvement (Luttenberger et al., 2018). Sensitivity from teachers to students’ attitudes towards the subject and their identification of when students require additional help can alleviate mathematics anxiety (Aldrup et al., 2020; Lazarides and Buchholz, 2019). Furthermore, school leaders can reduce mathematics anxiety by fostering a supportive school culture and providing professional learning opportunities (Horne, 2022).
The 2022 PISA results show that in 64 countries and economies, students who receive more support from teachers tend to experience lower levels of mathematics anxiety. This correlation is particularly strong in Croatia, Estonia, Hungary and Norway. A positive disciplinary climate in classrooms, characterized by less noise and distraction from digital devices and better student attentiveness to teachers, also strengthens the impact of teacher support. Across all surveyed countries and economies, students who receive more family support show greater confidence in their ability to learn independently and generally have lower anxiety towards mathematics (OECD, 2023b).
Traditional mathematics curricula and an emphasis on high-stakes testing also significantly contribute to mathematics anxiety (Simzar et al., 2015). Mathematics interventions in India, the Islamic Republic of Iran, the Kingdom of the Netherlands, Saudi Arabia, the United Kingdom and the United States focusing on innovative pedagogical techniques have proved efficient in boosting confidence, reducing anxiety and improving performance (Balt et al., 2022; Dowker et al., 2016; Ramirez et al., 2018). However, the outcomes of certain interventions remain mixed, especially among primary school students (Balt et al., 2022). For example, in Belgium, an intervention targeting over 300 children aged 6 to 12 with digital game–based learning in mathematics showed no reduced anxiety levels, but did lead to improved performance (Vanbecelaere et al., 2020).
In response to the previously described individual, social and academic factors which contribute to mathematics anxiety, education systems worldwide are implementing targeted initiatives. To foster a constructive attitude towards mathematics, the United States introduced the Mathematical Mindsets programme. Based on interdisciplinary research spanning psychology, neuroscience and mathematics education, the programme aims to cultivate a broader understanding of mathematics by emphasizing fundamental concepts such as number sense, pattern recognition and algebra as a problem-solving tool. It adopts a pedagogical strategy centred on engaging students with open-ended tasks and explicitly reinforcing a growth mindset (Boaler, 2019b, 2019a). Rolled out across 10 school districts in various states, teachers reported a notable shift in students’ perceptions of their mathematical abilities and the nature of mathematics (Boaler et al., 2021).
To provide teachers with effective strategies and tools for boosting confidence and teaching mathematics effectively, England (United Kingdom), introduced the mathematics mastery reform in 2014, aimed at changing the pedagogical approach to teaching mathematics through an innovative professional development programme and exchange programme with other countries, including China. Establishing 32 maths hubs was central to operationalizing this reform (Department for Education, 2014). Evaluations commissioned by the Department for Education have demonstrated improvements in teachers’ understanding of teaching mathematics, confidence and willingness to discuss effective teaching methods. Consequently, students have become more confident, resilient and skilled in solving mathematics problems. They demonstrated a better grasp of mathematical concepts and more effectively expressed their understanding and areas where they needed help (Boylan et al., 2018, 2019).
To make mathematics less abstract and more relevant to daily life, thereby reducing its intimidation level and making it more enjoyable for students, countries are updating their curricula and integrating technology into mathematics learning. Estonia’s approach to mathematics education includes an initiative that offers options for integrating mathematics with other subjects within its mathematics curriculum. This initiative demonstrates the practical applications of mathematics across diverse disciplines such as languages, literature, natural and social sciences, art, music, technology, and even physical education (Estonia Government, 2023). Singapore’s Ministry of Education has implemented the Student Learning System, a comprehensive digital platform that provides curriculum-aligned resources for students and teachers, promoting self-directed learning experiences (Singapore Ministry of Education, 2024). A notable advancement within this framework is introducing an artificial intelligence-driven adaptive learning system explicitly tailored for mathematics (Singapore Ministry of Education, 2023).
Countries are also re-evaluating their assessment practices. Since 2019, Austria has introduced additional national assessments at grades 4 and 8, with the implementation process underway. These assessments aim to provide teachers with comprehensive insights into student mathematics competencies. In contrast, Ireland has decentralized its assessment approach, granting schools autonomy to select their preferred assessment instruments. Ireland has embarked on a holistic approach to student evaluation as part of broader reforms to the lower secondary cycle curriculum. This approach is encapsulated in the Junior Cycle Profile of Achievement, which integrates classroom-based assessments, state examination results, and comprehensive reporting on formal and informal learning, encompassing aspects of student well-being. Sweden, meanwhile, has proposed a comprehensive overhaul of its national assessment system through the Inquiry on National Tests. This proposal advocates for a new tripartite system comprising national tests, national assessment support materials and a mechanism for national knowledge evaluation (May and Chamberlain, 2024).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;