
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Preschool leadership needs attention";
export const lead = "Leadership in early childhood centres and preschools is critical.";
export const layout = "focus";
export const article = "access";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Preschool leadership needs attention." disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=196" mdxType="FocusDetails">
      <h1 {...{
        "id": "preschool-leadership-needs-attention"
      }}>{`Preschool leadership needs attention`}</h1>
      <p>{`High-quality and inclusive early childhood care and education (ECCE) plays a crucial role in laying the foundations for lifelong learning and well-being (UNESCO, 2021c). Leadership in early childhood centres and preschools is, therefore, critical (Rodd, 2020). Leaders can influence work environments, the quality of care and education, and children’s educational outcomes (Cheung et al., 2019). Good leadership is also essential for fostering change (Fonsén et al., 2022; Thornton, 2020). Yet early studies reported that leaders had a limited awareness of their role (Muijs et al., 2004). `}</p>
      <p>{`Leadership needs at this education level are distinct from those of other education levels due to the unique developmental needs of young children and the additional care responsibilities often involved (Waniganayake et al., 2012), which has raised calls for ‘caring leadership’ (Siraj and Hallet, 2014). A focus on pedagogical leadership is, therefore, even more important in early childhood education (Fonsén, 2013). In Finland, research in early education units in 14 municipalities showed that leaders with stronger pedagogical competences were associated with a range of positive child outcomes, including related to learning, emotions and social relationships (Fonsén et al., 2022).`}</p>
      <p>{`Early studies highlighted the reluctance of ECCE educators to be designated as ‘leaders’ (Muijs et al., 2004). Many emphasized the need to see their work as a collaborative effort (Heikka, 2014). One of the reasons is the high prevalence of collaborative work with other actors involved in childcare, such as health institutions (Fonsén et al., 2022). The 2018 Starting Strong Teaching and Learning International Survey (TALIS) covered leaders of centres for children under 3 in Denmark, Germany, Israel and Norway, and preschool leaders in Chile, Denmark, Germany, Iceland, Israel, Japan, Norway, the Republic of Korea and Türkiye. It indicated that about two thirds of ECCE leaders communicated with staff and/or leaders from other ECCE centres at least on a monthly basis, and one third regularly consulted with child development specialists (OECD, 2020). Establishing relationships with families and communities is also vital. In Chile, the Framework for Good Teaching in Early Childhood Education guides educators on pedagogical reflection and practice, promoting family involvement in most domains (Chile Undersecretariat for Early Childhood Education, 2019). In TALIS countries, four in five ECCE leaders communicated with children, families or social services (OECD, 2020). `}</p>
      <p>{`ECCE leaders must balance operational tasks with a vision while working with very young children. They need to oversee daily activities, manage resources, ensure compliance with regulations, and maintain a safe and nurturing environment for children. On average, preschool leaders spent around 30% of their time on administrative tasks across TALIS countries, with those in Norway (49%) spending almost four times as much time as those in Israel (14%) (OECD, 2020). In smaller centres or in centres for children under 3, leaders spend even more time on administrative tasks (Fonsén et al., 2019; Hujala et al., 2016). Pedagogical leadership made up more than 25% of preschool leaders’ time in Chile, Iceland, Japan and the Republic of Korea. `}</p>
      <FocusImage size="large" src={"29-ecce_leaders.png"} alt="Early childhood care and education leaders devote more time to administration than pedagogy" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=197" mdxType="FocusImage" />
      <p>{`In some countries, leaders who received training in early childhood education or pedagogical leadership spent more time on pedagogical tasks. For example, leaders in Germany and Israel who received focused ECCE training reported higher engagement in pedagogical activities compared to those without such preparation (OECD, 2020). Responsibilities can be particularly challenging for directors of multiple centres. In Finland, at least two in three directors reported leading more than one centre, which makes sharing leadership with other staff very important (Halttunen and Waniganayake, 2021; Heikka, 2014).`}</p>
      <p>{`While some countries have set specific requirements for ECCE leaders (Rodd, 2020), others leave directors to learn on the job (Modise et al., 2023). `}</p>
      <p>{`In Australia, the National Quality Framework requires ECCE leaders to have formal qualifications in both early childhood education and management, ensuring they are well-prepared to handle the complexities of their roles (ACECQA, 2020). Canadian provinces have various requirements for ECCE centre directors and supervisors but they rarely include specific training provisions (McCuaig et al., 2022). In Finland, the 2018 Early Childhood Education and Care Act requires ECCE centre directors to have the same qualifications as ECCE teachers and social workers, as well as skills in management. Starting in 2030, they will need to have a master’s degree in early childhood education. The Malaysian Qualification Agency programme standards require ECCE centre heads to have at least a master’s degree in ECCE and three years of working experience in ECCE. In Singapore, ECCE leaders must have at least three years of management experience in a preschool and hold relevant teaching and leadership diplomas (Singapore Ministry of Education, 2024). In the United States, directors hired after 2016 under the Head Start programme needed to have a bachelor’s degree and experience in staff supervision, fiscal management and administration (United States Department of Health and Human Services, 2019).`}</p>
      <p>{`In Colombia, a 2002 decree proscribes that for preschool (and for basic rural primary school) directors, a bachelor’s degree in education and four years of professional experience are required, while six years are required in other primary schools. In Côte d’Ivoire, preschool directors must hold the State Diploma of Permanent Educators and a teaching certificate. In Indonesia, aspiring directors must comply with specific criteria specified in the 2005 National Standards, including being an active kindergarten teacher, possessing academic qualifications and proficiency as an educator per relevant legal requirements, having at least three years of teaching experience, and demonstrating leadership and an entrepreneurial aptitude in education. In Jamaica, the 2005 Early Childhood Act and Regulations prescribe that an ECCE institution director or manager must be a qualified teacher, i.e. a teacher with a bachelor’s degree in education or a diploma in teaching.`}</p>
      <p>{`Some countries have emphasized policies requiring directors to be equipped with the necessary leadership skills. Leadership preparation is a key component of directors’ professionalism, which is an important dimension of pedagogical leadership (Fonsén et al., 2022). However, many countries lack appropriate preparation at this education level (Diale and Sewagegn, 2021; Tefera, 2018). In New Zealand, the Te Whāriki early childhood curriculum emphasizes the role of leadership in supporting culturally responsive and inclusive practices (Howley-Rouse, 2023; New Zealand Ministry of Education, 2024). ECCE leaders are expected to undergo continuous professional development, particularly in areas of indigenous knowledge and inclusive pedagogy. Singapore’s Early Childhood Development Agency provides structured leadership training that includes pre-service and in-service programmes focused on management, staff development and curriculum oversight (ECDA, 2021).`}</p>
      <p>{`Access to mentorship and professional networks is critical for ECCE leaders to share experiences and address challenges. In many countries, especially in low-resource settings, leaders are isolated and lack the support needed to develop leadership skills. Communities of practice are essential to supporting ECCE leadership (Ratner et al., 2018; Watson, 2024).`}</p>
      <p>{`The absence of formal training contributes to disparities in how ECCE leaders manage centres, staff and learning environments. ECCE leaders often face significant challenges in securing adequate resources. In Bangladesh and Kenya, ECCE centres often operate with minimal funding, and leaders struggle with limited infrastructure and teaching materials (Oloo et al., 2023; Rashid and Akkari, 2020). High turnover is another challenge, exacerbated by stressful working conditions and low pay (Alchin et al., 2019; Modise et al., 2023b). In the United States, the turnover rate for ECCE centre directors was high in at least one in three centres surveyed in 2019, with large shares of staff leaving their roles due to the long working hours, administrative burdens and inadequate compensation (Amadon et al., 2023). This affects the continuity of care and education provided to children.`}</p>
      <p>{`In many countries, ECCE leaders’ roles and responsibilities are not clearly defined in national policies. As a result, leaders can operate in a grey area, with unclear expectations and limited support (Modise et al., 2023a). However, countries including Australia, Finland, New Zealand and Singapore have developed comprehensive policies that clearly define ECCE leaders’ roles. This has helped ensure a structured approach to leadership and higher quality standards across the sector (Rodd, 2020).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-access"
      }}>{`Focuses published in Access`}</h4>
      <hr></hr>
      <FocusThemeList theme="access" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;