
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Many families have trouble repaying student debt";
export const lead = "Student loans have become an increasingly popular instrument to promote affordable access to tertiary education.";
export const layout = "focus";
export const article = "finance";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Many families have trouble repaying student debt" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=272" mdxType="FocusDetails">
      <h1 {...{
        "id": "many-families-have-trouble-repaying-student-debt"
      }}>{`Many families have trouble repaying student debt`}</h1>
      <p>{`Student loans have become an increasingly popular instrument to promote affordable access to tertiary education. They are currently available in over 70 countries, to varying degrees. The average share of tertiary students receiving a public or government-sponsored student loan in 19 mostly high-income countries was 40%, ranging from 1% in Switzerland to 42% in South Africa and 84% in New Zealand. Student loans can increase access at a lower cost to governments than tuition subsidies or scholarships and grants and can even help improve equity, completion rates and graduates’ labour market outcomes. Student loans that also cover living costs allow students to be financially independent from their parents (Ziderman, 2017).`}</p>
      <FocusImage size="auto" src={"17-studentdebt_1.png"} alt="Almost all students receive a loan in some countries, almost none in others" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=272" mdxType="FocusImage" />
      <p>{`Often, however, student loans fall short of their promises of increased access and affordability. In Brazil, of the 2.2 million new student loans issued between 2009 and 2015, fewer than half translated into new enrolments; the majority of new loans were taken by students who were already enrolled or would have enrolled anyway (Brazil Ministry of Economy, 2017). In the United States, much of the increase in national student debt fed tuition spikes rather than new enrolments (Lucca et al., 2017).`}</p>
      <p>{`Student loans may also have different uptake rates by population group or programme type. Studies show that the risk-averse, who may be over-represented in some ethnic or immigrant groups or among poorer households, are less likely to take on student loans (Boatman et al., 2017). In Japan, debt aversion in poorer families diverts students from general to vocational high schools, hindering their prospect of entering tertiary education (Furuta, 2021).`}</p>
      <p>{`The high levels of debt many students carry into adulthood call into question whether student loans are actually affordable. Average debt at graduation varies considerably by country and depends on the level of tuition and living costs, the amount of aid or tuition subsidies, and loan repayment conditions. In England (United Kingdom), where nearly 95% of tertiary education students have a student loan, the average debt at graduation is US$50,000 (OECD, 2019). The level can quickly increase if students have trouble repaying, as institutions may add late fees or, as in the United States, use for-profit debt collection agencies that can add 30% to 40% to the original amount (Kolodner, 2021).`}</p>
      <p>{`More than the absolute value of the debt, what matters is students’ ability to repay it. In many countries, the proportion of borrowers’ income required to repay loans is excessive, especially for the least well-off graduates. In Brazil, a simulation exercise estimated that the repayment burden for female graduates in the bottom quintile ranged from 100% to 55% between ages 25 and 37 (Dearden and Nascimento, 2019). In Indonesia, it can vary from around 30% in a relatively high-income area (Java) to around 85% in a relatively low-income area (Sumatra) (Chapman, 2016). In Viet Nam, simulations show repayment burden varying between 20% and 85%. Even graduates in developed countries face high repayment burdens, ranging from 50% for public lawyers in the United States to 70% for women in former East Germany (Chapman, 2016; Chapman and Lounkaew, 2015).`}</p>
      <p>{`High levels of student loan defaults and payment delays, therefore, are hardly surprising. In Brazil and Chile, over 40% of the student loan contracts in the amortization phase had at least three months of payment delays, enough to harm borrowers’ credit reputation. The situation is even more concerning for those who drop out, as they become more likely to have lower earnings, delay payments and default on their loan. In Chile, over 70% of borrowers who dropped out have notable delays in student loan payments, compared with 34% of those who graduated (Ingresa, 2019). In the United States, late payments and defaults are highest at low levels of debt – around US$2,000 – likely from students who dropped out early (D'Amato, 2021).`}</p>
      <FocusImage size="large" src={"17-studentdebt_2.png"} alt="Repayment difficulties are becoming more common in Brazil and Chile" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=273" mdxType="FocusImage" />
      <p>{`Difficulties in repaying student loans can snowball into further financial troubles, as households have less disposable income and a damaged credit reputation, both of which can hinder access to further credit. Student loan borrowers are less likely to buy homes (Mezza et al., 2019) and save for retirement (Rutledge et al., 2018). And as easy as it may be to get a student loan, in many countries it is nearly impossible to get rid of them, even through debt settlement procedures or bankruptcy (Eurofound, 2020; Latham, 2020).`}</p>
      <p>{`Accumulated across many families, a high level of outstanding student debt could pose risks to a country’s economy. In the United States, where student debt nearly tripled between 2007 and 2019 (Latham, 2020) to US$1.6 trillion (Federal Reserve, 2020), there are concerns that this is hampering small businesses’ growth (Ambrose et al., 2015) and jeopardizing households’ ability to weather financial crises (Elliott and Nam, 2013). Once student debt has ballooned, there is no easy way out for governments, as forgiveness programmes may be considered regressive and unfair, and may lead to increases in tertiary education costs (D'Amato, 2021; Lowrey, 2020).`}</p>
      <p>{`Some countries have tried to address these concerns by imposing a debt ceiling, but maximum loan amounts are often not enough to cover both fees and living costs. Under-borrowing can cause its own problems, such as lowering access to loans. It may also push students to work longer hours (Black et al., 2020), which can have perverse effects on academic performance (Callender, 2008; Kalenkoski and Pabilonia, 2010), physical and mental health (Oviatt et al., 2017) and completion time (Darolia, 2014). In the United States, student loan caps have led parents to increasingly take on debt to help finance their children’s education – usually at a time in their lives when income is stagnant and soon to decline as they enter retirement. Between 2014 and 2019, the number of recipients of the federal loan programme Parent PLUS increased by 13% and the amount borrowed by 36%. The increase in borrowing has been accompanied by a rise in defaults: In 2019, US$96 billion was outstanding from 3.6 million parents (Fletcher et al., 2020).`}</p>
      <p>{`A more promising policy reform has been a shift from the widely used time-based repayment loans (mortgage style) to income-contingent loans. A growing number of countries have adopted full or partial income-contingent loan systems, including Australia, Ethiopia, Hungary, the Kingdom of the Netherlands, New Zealand, the Republic of Korea, South Africa, the United States and England (United Kingdom) (Britton et al., 2019; Yizengaw, 2007; Ziderman, 2017).`}</p>
      <p>{`In addition to lowering administrative costs for governments, income-contingent loans fix repayment burdens to reduce the possibility of facing repayment hardships in the future. Australia set the maximum repayment proportion of annual income for such loans at 8%, New Zealand at 9% and England and Wales (United Kingdom) at 10%. By providing insurance against low earnings or unemployment, the introduction of such loans has increased overall access to tertiary education in some countries. In Australia, the introduction of tuition fees combined with income-contingent loans increased overall tertiary enrolment, including for students from low-income households (Chapman, 2016).`}</p>
      <p>{`Success stories in high-income countries have helped advance this type of loan reform in low- and middle-income countries. Experts from Australia and the United Kingdom, for example, helped develop income-contingent loan proposals adopted in Brazil and Ethiopia, and are involved in public debates on the topic in Colombia and Malaysia (Chapman and Dearden, 2018; Filizola, 2019; IPEA, 2019; Woodhall, 2007). However, the suitability of income-contingent loans in less developed countries is debated. Income-contingent loans require a universal regime of income taxation or social security collection that identifies, tracks and collects repayments (Ziderman, 2017). Poorer countries face challenges such as large informal sectors or insufficient short-term government funding of the type needed for such programmes. In Ethiopia, the graduate tax (a modified type of income-contingent loan) introduced in 2003 has had low cost recovery (Portela and Gebremedhin, 2020), likely due to the federal system’s weak collection capacity, lower-than-expected graduate earnings and high levels of brain drain (Woldegiorgis, 2008).`}</p>
      <p>{`Student loans can be a powerful tool to alleviate financial constraints and increase access to tertiary education, but policies and design features have important implications for programmes’ ability to make tertiary education affordable and increase access equitably.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=558"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;