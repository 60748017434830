
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Scholarships for sub-Saharan African students are increasing in number but need to be inclusive";
export const lead = "Target 4.b calls for monitoring the number of scholarships available to developing countries, but there is no data collection mechanism.";
export const layout = "focus";
export const article = "finance";
export const year = 2020;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Scholarships for sub-Saharan African students are increasing in number but need to be inclusive" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=314" mdxType="FocusDetails">
      <h1 {...{
        "id": "scholarships-for-sub-saharan-african-students-are-increasing-in-number-but-need-to-be-inclusive"
      }}>{`Scholarships for sub-Saharan African students are increasing in number but need to be inclusive`}</h1>
      <p>{`Target 4.b calls for monitoring the number of scholarships available to developing countries, but there is no data collection mechanism. past editions of the Global Education Monitoring Report proposed ways to fill the gap, but there has been insufficient funding interest. In-depth analysis of scholarship opportunities for sub-Saharan African students, conducted for this report, is a further step.`}</p>
      <p>{`The top 50 scholarship aid providers offered some 30,000 new scholarships in 2019 for 2020 entry. They amounted to 94% of the estimated total number of scholarships targeted to sub-Saharan African students, according to a mapping of more than 200 providers. In other words, a small number of large providers accounted for most scholarships in the region. Undergraduate scholarships accounted for 56%. Scholarships reached the equivalent of 0.4% of the 8.1 million sub-Saharan African tertiary education students.`}</p>
      <FocusImage size="medium" src={"8-ScholarshipsSSA_1.png"} alt="A majority of tertiary education scholarships for sub‑Saharan African students are for undergraduate study" href="https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=316" mdxType="FocusImage" />
      <p>{`Government initiatives dominate scholarship provision for sub-Saharan African students. The Chinese government, through various agencies, was the single largest provider, with over 12,000 opportunities annually. The next largest government providers were South Africa, the Russian Federation, the United Kingdom, Türkiye, Egypt, India, Germany and Japan. International organizations, including the UN High Commissioner for Refugees, the African Union, the European Union and the World Bank, were also prominent in the top 25. Corporations, including public enterprises and corporate foundations, represented 5 of the top 50 providers. The MasterCard Foundation and ABSA Bank contributed 95% of all corporate scholarships. `}</p>
      <p>{`Although aid for scholarships has been stagnant since 2010 (UNESCO, 2018), opportunities in sub-Saharan Africa have increased since 2015 and will likely increase over the next five years. At least 10 of the top 50 providers and 30 smaller programmes have launched initiatives or expanded programming in the last five years. `}</p>
      <p>{`In 2018, China increased total scholarships (new and ongoing) for 2019–22 to 50,000, up from 30,000 in the previous three years. This represents an additional 5,000 scholarships per year, or 16% of the estimated 2019 total. DAAD, the German Academic Exchange Service, increased scholarships to the region by 900 between 2014 and 2017. In 2018, the UK government pledged a further 100 scholarships annually through its Chevening programme. The World Bank recently launched a programme with more than 500 postgraduate scholarships for western Africa. In 2015, India announced 50,000 scholarships over the next five years in a pledge to the African Development Bank. Smaller programmes continue to emerge, but philanthropic scholarship funding was heavily concentrated among a small number of large providers and reliant on their continued interest. The MasterCard Foundation was estimated to account for four times the volume of scholarship funds as the second-biggest philanthropic programme. `}</p>
      <p>{`Scholarships must be well-targeted to affect sustainable development. They must create opportunities otherwise unavailable. Assessing inclusion is difficult. Donors and providers tend not to have verifiable metrics; they may not even have inclusion objectives. One approach to defining programmes as inclusive is to consider those that offer full funding (tuition and living expenses) and have one or more of the following objectives: (a) access and empower young people from marginalized groups, (b) prepare applicants for decent work, and (c) promote sub-Saharan African universities through programming involving long-term partnerships with institutions, including local education non-profit and non-government organizations (NGOs). `}</p>
      <p>{`Some 30% of scholarships can thus be classified as inclusive, and 31 of the top 50 providers as offering inclusive scholarships. All 10 international organizations in the top 50 are classified as offering inclusive scholarships and account for 28% of the total number of such opportunities. The African Union, the EU Erasmus+ programme, DAAD, the KfW Development bank and the World bank have launched or accelerated inclusive programmes. one particular inclusion concern is related to the approximately 60% of recipients who study outside sub-Saharan Africa. They often face application processes, selection procedures and learning environments that are challenging for sub-Saharan African applicants, and existing programmes do not cater sufficiently to their needs.`}</p>
      <p>{`Only 6% of the poorest 20% in sub-Saharan Africa complete upper secondary education, and almost none attend post-secondary. As part of the mapping for this report, the feasibility of collecting data on key performance indicators for inclusive scholarship programmes was trialled anonymously with 20 scholarship providers (10 in the top 50) that exemplified good practice in scholarship programming. one objective was to ascertain how many scholarships were awarded to members of disadvantaged groups. Most providers could not provide detailed background information, e.g. whether recipients had rural backgrounds or a disability. Almost all providers measured the share of female recipients. Two programmes targeted women exclusively; the share in other programmes ranged from 32% to 57%, with most at the upper end of the range.`}</p>
      <p>{`Whether scholarships benefit recipients is another important indicator – in particular, whether recipients complete their studies, stay in or return to sub-Saharan Africa, and/or transition into decent work or further education. A few programmes involved agreements with graduates compelling them to return to home countries. Few were legally binding.`}</p>
      <p>{`All providers tracked programme completion. In all but two cases, completion rates were 85%, including programmes operating at African universities. This contrasts favourably with low undergraduate and postgraduate completion rates across the region, including in South Africa. However, in the absence of student background information, high rates may reflect privileged backgrounds. Movement information was generally available one but not three or five years after graduation. Many providers aside from the 20 studied more closely, especially those offering scholarships for study outside Africa, had student return rates of 25% to 45%. providers operating in South Africa generally had good information and above 90% success rates when it came to graduates finding quality jobs. Information outside South Africa was much sparser.`}</p>
      <p>{`Five key principles to target and support marginalized young people emerged. First, inclusive scholarships require adequately researched strategies. The Dell Young leaders programme in South Africa had strong evidence-based strategic planning based on research on drivers of dropout. The programme offered funding to supplement existing financial aid, study and pastoral support, and direct links to the labour market.`}</p>
      <p>{`Second, general calls for applications often fail to reach students at risk of exclusion. A cost-effective alternative is to build long-term relationships with trusted local organizations that can play a role in nominating and interviewing marginalized students. The Ford Foundation’s International Fellowship programme sponsored 562 scholars from Kenya, Nigeria and South Africa over 10 years, until 2014. It targeted leaders in activism and social change, with an emphasis on women, marginalized ethnic groups and young people living in poverty or with disability. long-term partnerships with organizations, such as the Forum for African Women Educationalists, supported successful targeting.`}</p>
      <p>{`Third, marginalized students need tailored programmes to support their university experience, including orientation, study and life skills training, mentoring and pastoral support, workplace preparation and psychosocial support. The Moshal Scholarship program in South Africa introduced an early warning system to monitor students’ academic progress. It offered monthly face-to-face support meetings and responded quickly to major life events.`}</p>
      <p>{`Fourth, scholarship providers would benefit from tracking graduates’ pathways to livelihood opportunities and refining planning and modelling to improve employment outcomes. Alumni tracking showed that disadvantaged graduates faced prejudice and obstacles in finding decent work, even when qualified. The Regional Universities Forum for Capacity building in Agriculture, a pan-African organization offering students from rural communities postgraduate scholarships at local universities, closely aligned degrees with agricultural opportunities in students’ communities, resulting in 75% of graduates finding decent work in the formal sector or as entrepreneurs.`}</p>
      <p>{`Fifth, providers often operate in isolation, with no culture of cooperation and few platforms to facilitate coordination. This was reflected in a strong geographical bias, with most programmes either targeted to, or taken up by, students from a small number of the more developed anglophone countries. A notable example of cooperation, emphasizing the importance of providing scholarship opportunities to a wider group of francophone, lusophone and low-income countries, was the Ashinaga Africa Initiative, a Japanese non-profit organization. Working with national governments, local NGos, international universities and staff on the ground, it has sponsored 184 undergraduates from 44 countries since 2014, the vast majority being first-generation scholars.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`This focus article draws on research by Education Sub Saharan Africa (ESSA). The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000373718/PDF/373718eng.pdf.multi.page=515"} mdxType="ReferenceButton">
  2020 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;