
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "School infrastructure must adapt to climate change";
export const lead = "Two types of climate-related adaptations stand out: thermal comfort and resilience to natural disasters such as storms and floods.";
export const layout = "focus";
export const article = "quality";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="School infrastructure must adapt to climate change" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=264" mdxType="FocusDetails">
      <h1 {...{
        "id": "school-infrastructure-must-adapt-to-climate-change"
      }}>{`School infrastructure must adapt to climate change`}</h1>
      <p>{`Climate change and education are interlinked: climate change disrupts education while education is key to climate change mitigation and adaptation efforts (UNESCO and MECCE, 2024). An important dimension of this mutual relationship is the physical infrastructure of school buildings. Schools must limit their impact on the environment as well as the impact of the changing environment on them. `}</p>
      <p>{`Schools are a major source of energy and water consumption. In France, schools top the list of energy-consuming municipal public buildings, accounting for 30% of all energy (France Ministry of National Education and Youth, 2023). In the United States, public schools alone occupy 2 million acres of land. They are responsible for 53,000 tons of food waste and operate 480,000 school buses (Bauld, 2021). UNESCO’s Greening Every Learning Environment project provides a quality standard for greening schools that integrates sustainability principles and climate action across four core areas, one of which is facilities and operation. The publication sets out several examples of low to high resource-intensive steps that can help schools reduce energy and water consumption. These include the development of rainwater harvesting, composting systems, and the regular maintenance of heating and ventilation systems (UNESCO, 2024).`}</p>
      <p>{`Minimizing the environmental impact of schools’ infrastructure must go hand-in-hand with increasing their resilience to the consequences of climate change. Two types of climate-related hazards stand out and have been the object of school adaptations around the world: making buildings usable and comfortable during periods of high temperatures and making them more resilient to natural disasters such as storms and floods.`}</p>
      <h2 {...{
        "id": "improving-thermal-comfort"
      }}>{`Improving thermal comfort`}</h2>
      <p>{`Dealing with rising temperatures that hamper students’ well-being and ability to focus has been a major concern in many countries. High temperatures negatively impact cognitive performance and decrease the rate of learning (Park et al., 2021). They also often lead to missed school days. In Bangladesh, school closures due to temperatures above 43 degrees Celsius (°C) left nearly 33 million children out of school for a few days in the first semester of 2024. Similar closures or shortening of school days took place during this period in Cambodia, India, the Philippines and South Sudan, where temperatures rose to 45°C (Aggarwal, 2024; Dickie et al., 2024). Increasing temperatures have also led many schools to close or function part-time during periods of extreme heat in the milder climate areas of the United States. It has been estimated that over 13,700 public schools would need air conditioning due to rising temperatures at a cost of USD 40 billion (Phillips and Penney, 2024).`}</p>
      <p>{`School buildings can mitigate the effect of elevated temperatures. France’s school renovation programme, launched in 2023, helps municipalities fund works aimed at making schools more resistant to the increasingly longer heatwaves (France Ministry of National Education and Youth, 2023). Solutions range from high-budget items, such as installing air conditioning in classrooms, to simpler steps such as white-coating exterior walls and using blinds and awnings that can be modulated according to the season. The government also encourages the installation of green recreational spaces and increased vegetation surrounding schools, as the simple presence of trees and plants can help filter sunlight and keep the air cooler (France Ministry of National Education and Youth, 2020).`}</p>
      <p>{`In Indonesia, replacing dark roofs with a coating of white paint reduced inside temperatures by over 10°C. In Kenya, the government aims to plant 15 billion trees around schools by 2032, which can lower temperatures by 1°C to 5°C. When building new schools or classrooms, climate-responsive designs and materials can help keep temperatures lower at minimal costs. Integration of cross-ventilation, use of materials that offer thermal protection such as clay, and roof designs that help pull in cool air and release hot air can improve thermal comfort while reducing schools’ ecological footprint (Venegas Marin et al., 2024).`}</p>
      <p>{`Extreme heat disproportionally affects the poorest regions in the world; within countries, it disproportionally affects the poorest students who are more likely to attend schools with fewer resources (Venegas Marin et al., 2024). In Brazil, where temperatures can reach high levels in much of the country, only 35% of schools have any green areas, which are a common solution to filter sunlight and keep the air cooler (INEP, 2024). Often those without it are those which need it most. Schools in areas with the highest maximum temperatures, most of which are in the poorer North and Northeast regions of the country, are less likely to have green areas. It is not just a question of money, however. The state of São Paulo is the richest in the country and has the lowest share of schools with green areas (16%). In 2024, the city of São Paulo launched a project to increase green areas in schools to help regulate temperature, diminish pollution and flooding, and give students and communities opportunities to learn about sustainable development and climate resilience (CicloVivo, 2024).`}</p>
      <FocusImage size="small" src={"33-climate_infra.png"} alt="In Brazil, schools in hotter regions are less likely to have green spaces" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=268" mdxType="FocusImage" />
      <h2 {...{
        "id": "improving-resilience-to-natural-disasters"
      }}>{`Improving resilience to natural disasters`}</h2>
      <p>{`Climate-related hazards such as wildfires, storms, floods, droughts and rising sea levels can have devastating effects on societies and educational systems. Over the past two decades, at least three quarters of extreme weather events that impacted at least 5 million people led to school closures (Venegas Marin et al., 2024). In Pakistan, historical floods in 2022 interrupted the education of over 3.5 million students, with over 20,000 schools damaged or destroyed (UNESCO, 2022). In the Philippines, over 20% of schools flood at least once every year (Venegas Marin et al., 2024).`}</p>
      <p>{`The impact of natural disasters on school infrastructure cannot be fully remedied, but factors such as poor building design and inadequate construction or materials can accentuate it. Lack of safe water, sanitation and hygiene facilities in schools can increase the risk of overlapping hazards after a disaster, such as contamination from ineffective sewage after a flood, thus making recovery harder and longer (UNICEF, 2021). Poor commuting infrastructure can also impact education. After two cyclones hit Tanna Island, Vanuatu, in February 2023, schools that survived were only able to function for half-days for several months because the muddy roads made the commute more difficult for students and teachers (Voloder, 2023).`}</p>
      <p>{`Because schools face different types of climate risk, solutions must be context-specific. In Rwanda, over 1,300 schools are being equipped with retaining walls to alleviate the risk of landslides due to floods and rainstorms. In Viet Nam, the risk of floods has been reduced by building schools with elevated foundations, often constructed on stilts (Venegas Marin et al., 2024). At the same time, many organizations have long championed general guidelines to enforce compliance with building codes, conduct regular maintenance and make risk-informed decisions for new schools.`}</p>
      <p>{`The Comprehensive School Safety Framework was developed by the Global Alliance for Disaster Risk Reduction and Resilience in the Education Sector, a multistakeholder platform co-chaired by UNESCO and UNICEF. The Framework provides a common model for ensuring school safety, with safer learning facilities as one of its three pillars (GADRRRES, 2022). The Framework also serves as the foundational document for the Worldwide Initiative for Safe Schools, a global partnership with 58 signatory countries for peer learning and promoting national actions on school safety (GADRRRES, 2021; Petal et al., 2017). In 2015, UN Member States adopted the Sendai Framework for Disaster Risk Reduction 2015–2030, which sets seven targets, one of which is to ‘substantially reduce disaster damage to critical infrastructure and disruption of basic services, among them health and educational facilities, including through developing their resilience by 2030’ (UNDRR, 2021).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;