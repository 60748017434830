
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Household wealth does not capture all dimensions of poverty";
export const lead = "SDG thematic indicator 4.5.3 aims to capture countries’ efforts to redistribute public resources to support disadvantaged groups.";
export const layout = "focus";
export const article = "equity";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Reducing poverty is at the heart of the international community’s efforts to leave no one behind in the 2030 Agenda." disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=301" mdxType="FocusDetails">
      <h1 {...{
        "id": "household-wealth-does-not-capture-all-dimensions-of-poverty"
      }}>{`Household wealth does not capture all dimensions of poverty`}</h1>
      <p>{`Reducing poverty is at the heart of the international community’s efforts to leave no one behind in the 2030 Agenda. It can be conceptualized in numerous ways. International statistics on poverty to monitor SDG 1 are based on measures of income or consumption. But the detailed information needed to estimate poverty is often not available in the international multipurpose household surveys that are used to monitor progress in other SDGs, including SDG 4 on education. Instead, the surveys estimate a proxy measure for poverty based on household possessions and house amenities (Hannum et al., 2017). Using this wealth-related measure to disaggregate education indicators has limitations, which affect cross-country comparability.`}</p>
      <p>{`Being poor in terms of wealth does not equate to being poor in terms of income. Wealth and income are expected to be positively correlated: Higher income allows households to save and accumulate wealth, and higher wealth can increase income through investments or easier access to credit. However, the relationship is often not that strong or clear. In the United States, for example, the correlation between total household income and net financial worth is positive, but relatively low (0.5); if only wage or salary income is considered, the correlation with net financial worth drops further (0.2) (Keister, 2018). Low correlation between wealth and income may mean that households spend their earnings and do not save or that they have low income but high wealth through, for example, inheritance.`}</p>
      <p>{`The share of children who received the minimum number of meals in the past day can be a proxy for income, as opposed to accumulated wealth. In many countries, the share of children aged 6 to 23 months not receiving the minimum number of meals is about the same across wealth quintiles. In the Democratic Republic of the Congo, for example, the 66% of children not receiving the minimum for their age are almost evenly split among the five wealth quintiles. By contrast, there is a considerable wealth gap in the Lao People’s Democratic Republic: 40% of children in the poorest households in terms of the wealth index, but only 13% of their peers in the richest households, did not receive the minimum meals.`}</p>
      <FocusImage size="medium" src={"14-householdwealth_1.png"} alt="Income and wealth are not always well correlated" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=301" mdxType="FocusImage" />
      <p>{`Wealth, which tends to be measured at the household level, does not always capture child-specific deprivation. Although this measure is likely related to household members’ living conditions, it does not directly translate to the individual level. This is particularly true for children, for whom resource allocation may be particularly unequal (Hannum et al., 2017). SDG target 1.2 is the first global poverty target explicitly mentioning children. Growing recognition that children’s needs and living standards can differ from those of adults in the same household has led to development of measures of children-specific deprivation (Guio et al., 2018). In the EU Survey of Income and Living Conditions 2009 and 2014, ad hoc modules on deprivation contained questions on children-specific goods, such as shoes, clothes and books, as well as participation in social activities, such as celebrations, school trips and holidays (Eurostat, 2014).`}</p>
      <p>{`Another example is UNICEF’s Multidimensional Overlapping Deprivation Analysis, aimed at identifying and quantifying child poverty through various dimensions, including availability of books and toys. The share of deprived 3- to 4-year-olds varies considerably across low- and lower-middle-income countries and is not determined by the country’s wealth. Ghana’s GDP per capita, for example, is slightly higher than that of Bangladesh, but its share of 3- to 4-year-olds with no books or toys is nearly five times higher (33% vs 7%). Within countries, the prevalence of child deprivation is again not fully determined by household wealth. Regarding the population of deprived children, 78% live in the country’s poorest households in the State of Palestine, but only 27% in Chad. Indeed, in several countries, 10% of deprived children are in the richest households and over 30% of children in the poorest households are not deprived.`}</p>
      <FocusImage size="medium" src={"14-householdwealth_2.png"} alt="Income and wealth are not always well correlated" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=303" mdxType="FocusImage" />
      <p>{`As with household wealth, the level of child deprivation can be an additional strong predictor of education outcomes. In Togo, 3- to 4-year-olds who have no books or toys are significantly less likely to have attended early childhood education than those with at least one such item. About 40% of 3- to 4-year-olds in the country are deprived of both items, and there is no significant difference between their probability of attending early childhood education and that of children living in the 40% poorest households. But controlling for household wealth, being deprived of books and toys in Togo further decreases the probability of having attended early childhood education by four percentage points, on average across wealth levels.`}</p>
      <p>{`The effect is strongest for children in the richest households, where being deprived is associated with a decrease in probability of six percentage points (from 32% to 26%). Together, different measures provide a fuller understanding of households’ economic situation.`}</p>
      <FocusImage size="medium" src={"14-householdwealth_3.png"} alt="Income and wealth are not always well correlated" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=304" mdxType="FocusImage" />
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-equity"
      }}>{`Focuses published in Equity`}</h4>
      <hr></hr>
      <FocusThemeList theme="equity" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=565"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;