
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Civic education can shape young citizens’ political behaviour";
export const lead = "Civic education programmes must address two particular challenges.";
export const layout = "focus";
export const article = "learning";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Civic education can shape young citizens’ political behaviour" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=253" mdxType="FocusDetails">
      <h1 {...{
        "id": "civic-education-can-shape-young-citizens-political-behaviour"
      }}>{`Civic education can shape young citizens’ political behaviour`}</h1>
      <p>{`Target 4.7 seeks to empower students so they can contribute to more peaceful, tolerant, inclusive and secure societies, in their countries and beyond. Civic education programmes have evolved from the concept of a ‘citizenship of being’, where young people are seen as passively responding to static rights, duties and institutions, to a ‘citizenship of becoming’, where they actively appreciate diversity and seek to develop their sense of belonging through collaboration and communication, including by using technology (Gifford et al., 2014).`}</p>
      <p>{`Civic education programmes must address two particular challenges. First, overall political participation has been declining in electoral democracies. By one global estimate, voter turnout in presidential and legislative elections has fallen from 77% in the 1960s to 67% after 2010. This phenomenon presents a puzzle, as growing levels of education could have been expected to contribute to higher participation (Kostelka and Blais, 2021).`}</p>
      <p>{`Second, there are gaps in political aspirations and intended participation, which risks democracies not being representative. Girls often grow up convinced that political leadership is predominantly a male activity. They are more likely to expect to vote while boys are more likely to expect to run for office (Barber and Torney-Purta, 2009; Hooghe and Stolle, 2004). The 2022 International Civic and Citizenship Education Study (ICCS), a large-scale learning assessment of grade 8 students in 24 education systems, mostly from high-income countries, found that girls are less likely than boys to expect to actively participate in politics.`}</p>
      <FocusImage size="large" src={"32-civic_edu-1.png"} alt="Girls are less likely than boys to expect to actively participate in politics, but more likely to expect to vote and get informed about candidates" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=254" mdxType="FocusImage" />
      <p>{`In the United States, in an experiment in which children were asked to draw a political leader, the likelihood that girls would draw a man increased with age, from 47% among 6-year-olds to 75% among 12-year-olds, while the percentage of boys who did the same was stable at just above 70% (Bos et al., 2022). Girls are more drawn to social movement activities such as volunteering while boys are more likely to want to participate in violent protests (Hooghe and Stolle, 2004). A study of 14-year-olds in European countries showed that girls were much more likely to report they would vote in the future but less likely to want to be candidates themselves, with the effect persisting, even after controlling for factors such as socioeconomic status, immigrant background, school characteristic and political interest (Hooghe and Dassonneville, 2013). Gaps in expected political participation by socioeconomic status are even larger than those by sex and statistically significant across all countries that participated in the 2022 ICCS. Schools whose student populations have higher socioeconomic status are more likely to support citizenship norms such as voting, participating in debates and obeying the law (Treviño et al., 2021).`}</p>
      <FocusImage size="small" src={"32-civic_edu-2.png"} alt="Intended electoral participation is significantly higher among richer students" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=255" mdxType="FocusImage" />
      <p>{`So can civic education bolster political participation? Studies that have tried to identify a causal link between education (measured in different ways, such as coursework, changes in compulsory education legislation or specific education programmes) and political participation (measured by voter turnout) have yielded results that are mostly inconclusive, no matter how sophisticated the methodology (Willeck and Mendelberg, 2022). Recent studies that link civic education courses in secondary education and mandatory civic education examinations with adult voting patterns have also struggled to find a significant correlation between the two (Jung and Gopalan, 2023; Weinschenk and Dawes, 2022). A review of nine studies on civic education programmes found no significant effect on voting, although some positive effects were found among particular populations, such as the children of immigrant parents (Manning and Edwards, 2014).`}</p>
      <p>{`However, the absence of a causal relationship is not definite. It is important to recognize that, beyond classroom instruction, extracurricular activities, service learning and the school’s ethos can affect civic learning and engagement and compensate for a lack of civic resources at home and in the community (Campbell, 2019). It is also important to recognize that beyond traditional political participation measures, civic education helps to shape identity formation (ethnic, political or any other sort of self-image), agency and self-efficacy (Treviño et al., 2021). `}</p>
      <p>{`Analyses based on ICCS data have shown the importance of an open classroom climate and positive interactions between teachers and students to temper the impact of socioeconomic status (Brese et al., 2015). A study of English secondary schools also found positive effects of an open classroom climate on expected political participation, especially for disadvantaged groups, although the cumulative effect of civic education had a positive impact on participation only for white students (Weinberg, 2022). A study on US students found similar effectiveness on Black and Latino participation for civic education courses that had been informed by critical pedagogy, in contrast to courses that were more traditional and less critical of the status quo. In Germany, expected political participation among young adults was positively influenced by parental education level, occupational status and income, but the effect was smaller for students that followed an academic track instead of a vocational track (Barsegyan et al., 2024). `}</p>
      <p>{`Data from the 2022 ICCS also show some positive correlation between average civic knowledge scores and an index of expected political participation. Previous studies that used 2009 and 2016 ICCS data had also found civic knowledge to be a good predictor of expected political participation, support for equal gender rights and support for equal rights for all ethnic groups (Lauglo, 2013; Schulz and Fraillon, 2019). Students in schools that provide opportunities to learn and discuss political and social issues are more likely to include citizenship norms such as participating in national elections, discussing politics, working hard, obeying the law, respecting authorities, protesting against unjust laws, promoting human rights and participating in local communities (Treviño et al., 2021).`}</p>
      <p>{`There is less research on the impact of civic education in low- and middle-income countries. Examples include studies in Latin America that show the impact of civic knowledge on support for authoritarian regimes (Miranda et al., 2021) or on tolerance for corruption (Carrasco and Pavón Mediano, 2021). In Uganda, a randomized control trial evaluation showed the positive effect of civic education on shaping collective action towards demanding the satisfaction of basic common needs such as roads, medicine and food (Bananuka and Mugarra, 2023). But there is a clear need for more research to help guide effective policy in this area.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;