
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Peers affect individual education outcomes";
export const lead = "The composition of social groups can have a major impact on student achievement, educational trajectories and other relevant outcomes.";
export const layout = "focus";
export const article = "equity";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Peers affect individual education outcomes" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=233" mdxType="FocusDetails">
      <h1 {...{
        "id": "peers-affect-individual-education-outcomes"
      }}>{`Peers affect individual education outcomes`}</h1>
      <p>{`Individual merit is an ideal at the core of educational achievement. However, achievement does not rely solely on a student’s performance, but also on the characteristics and behaviour of the social group surrounding them. Students have little power to decide on the composition of their classrooms. However, the composition of social groups can have a major impact on student achievement, educational trajectories and other relevant outcomes. Education planners need to understand how the configuration of these social groups affects student outcomes and what actions can be taken to mitigate any detrimental impact. `}</p>
      <p>{`The term ‘peer effect’ addresses the linkage between any given individual outcome and the characteristics and behaviours of others in that individual’s reference group (Kline and Tamer, 2012). In education policy, peer effects refer to the extent to which learning or other relevant education outcomes are related to the student’s classroom or school peers, family, or neighbourhood (Barrios-Fernandez, 2023). Peer effects might have an impact on academic performance (Sacerdote, 2011), educational trajectories (Bursztyn et al., 2019), socioemotional skills (Shure, 2021), and behavioural components such as misconduct and truancy (Bennett and Bergman, 2021) and adult crime (Eren et al., 2022).`}</p>
      <p>{`Studies have shown that peer characteristics (e.g. ability, gender, immigration status, race and socioeconomic status) have an impact on students’ academic achievement (Barrios-Fernandez, 2023). A study in the Kingdom of the Netherlands showed an increase in students’ scores for low- and medium-ability students allocated to tutorial groups with similar ability peers (Booij et al., 2017). Peer effects have also been identified in higher education levels (Barrios-Fernandez, 2023). A study of economic science faculties in Greece found that a high concentration of low-achieving students lowers the academic performance of both high- and low-achieving students (Genakos and Kyrkopoulou, 2023). Regarding non-academic outcomes, a study in El Salvador found that heterogenous peer groups help reduce school violence (Dinarte, 2024). A study in lower secondary schools in two provinces in China found that ability grouping reduced mathematics anxiety for high-performing students (Gupta et al., 2023). `}</p>
      <p>{`It is important to note that peer effects are highly context-specific (Paloyo, 2020). A student’s response to their peers’ characteristics may vary by the type of characteristic. Peer effects may also vary by an individual’s characteristics, such as gender and race (Patacchini et al., 2017). A study conducted among English secondary schools found that girls benefited more from being with academically advanced students than boys (Lavy et al., 2012). In the US state of Texas, it was found that in grades 5 and 7, a higher classroom concentration of Black students worsened the academic results of Black students but did not have an effect on the academic outcome of white students (Hanushek et al., 2009). Results are not conclusive for immigrant students, as some studies have suggested that a high concentration of immigrant students may impact negatively both immigrants and non-immigrants, while other studies have found no such effect or that it only affects immigrant students (OECD, 2023). `}</p>
      <p>{`Peer effects may involve multiple grouping factors (e.g. by ability, gender, immigration status, race and socioeconomic status) affecting multiple outcomes (e.g. achievement, educational trajectories and truancy). Thus, despite the fact that peer effects have been widely studied, the size of the impact varies substantially depending on the context, outcomes, characteristics and behaviours measured (Barrios-Fernandez, 2023).`}</p>
      <p>{`Despite the heterogeneity of these results, the general consensus is that peer effects do have an impact on student outcomes and that this is, therefore, a policy challenge. If peer effects have a positive impact on student outcomes, they could be incorporated into the policy design to amplify the impact of educational programmes. On the contrary, if peer effects are having a negative association with student outcomes, it is necessary to address them to disentangle inequalities of educational trajectories across different social groups (Barrios-Fernandez, 2023). `}</p>
      <p>{`Thus, school policy mechanisms impacting student grouping and classroom concentration have a pivotal role in shaping this effect. The concentration can take shape in a vertical manner as students of the same age are assigned to different grade levels due mainly to grade repetition or differential access to pre-primary education. A horizontal concentration refers to students of different abilities, behaviour and interest being assigned to different schools, programmes or classes to better tailor instruction to their particular needs. The concentration and separation of students according to an observed characteristic, behaviour or ability carries the name ‘segregation’ when it takes place at the school level. When the same operation takes place between programmes (e.g. vocational and academic programmes), it is usually referred to as ‘tracking’, while concentration within the same class is labelled ‘ability grouping’ (OECD, 2023b). `}</p>
      <p>{`The grouping and concentration of students by ability also implies the separation and concentration of students by other characteristics (e.g. socioeconomic, immigration status and gender). Indeed, evidence has shown that the more stratified a school system is, the more likely disadvantaged students are placed in the least academically orientated and demanding learning environments (OECD, 2023b). `}</p>
      <p>{`Analysis of the 2022 PISA data shows that student concentration and grouping does not seem to have a direct correlation with mean scores in mathematics, but it does correlate with overall socioeconomic fairness (i.e. the impact that socioeconomic factors have on achievement). There is a negative correlation between socioeconomic fairness and the 2022 PISA isolation index of low-achieving students, which measures the degree to which low-achieving students in mathematics are separated from other groups: the higher the value, the higher the degree of isolation. The higher the value of the isolation index, the lower the socioeconomic fairness and thus the stronger the association between student socioeconomic background and their achievement.`}</p>
      <FocusImage size="small" src={"31-peer_effects.png"} alt="Socioeconomic background has a higher influence in schools with high concentrations of low-achieving students" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=234" mdxType="FocusImage" />
      <p>{`Many educational systems have grown more aware of the dangers that tracking might have for educational fairness and equality. New Zealand has shown consistently high levels of tracking in its educational system, which has had a negative effect on the educational trajectories for the Māori population. The Ministry of Education, along with the civil society organization Tokona te Raki, developed Kōkirihia, a plan to end tracking with three components: growing awareness, showcasing alternatives to tracking and asking organizations across the education sector to take action. One of the concrete measures taken was the integration of inclusive practices to the Core Practice Curriculum and the New Zealand Curriculum. The purpose is to develop an inclusive curriculum that will eliminate the need to separate students by ability. Since the implementation of these initiatives began, the percentage of 15-year-old students attending school where students are grouped by ability declined by 23 percentage points. The goal is for tracking to end by 2030 in the New Zealand education system (OECD, 2023b).`}</p>
      <p>{`A survey in Swedish municipalities showed how school superintendents develop different strategies to counteract the negative effects of school segregation (Trumberg et al., 2024). One of these strategies consists of reinforcements, which is additional support for schools in disadvantaged areas. A second strategy consists of dispersal initiatives, which is dispersing students to different schools to create more heterogenous student bodies. In Sweden, concrete dispersal initiatives are the redrawing of catchment areas or busing students to other schools than those closest to them. Finally, a third albeit less common strategy is merging, where a municipality closes two or more schools and creates a new one to ensure a more heterogenous student composition.`}</p>
      <p>{`Will all types of student groupings or concentrations inevitably present negative and unequal outcomes? Not necessarily, since it is also important to take into consideration just how effective the quality of the classes is. Grouping students with the same level of abilities should allow teachers to tailor the instruction to their needs and potentially benefit low-achieving students. A study among 121 primary schools in Kenya found that, even though all students benefited from high-achieving peers, tracking benefited low-achieving pupils by allowing teachers to teach at their level (Duflo et al., 2011).`}</p>
      <p>{`The mechanisms of peer effects and their relationship with teaching effectiveness merit attention (Barrios-Fernandez, 2023). It is also important to acknowledge that the causes of stratification, segregation and concentration might be structural. Income inequality, residential segregation, long-standing school policies on choice and selection, and the size of the private school sector can underpin student allocation and concentration as structural forces. Thus, policymakers will need to be much more radical on policy recommendations in this area (Gutiérrez et al., 2020) to promote equity and more.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-equity"
      }}>{`Focuses published in Equity`}</h4>
      <hr></hr>
      <FocusThemeList theme="equity" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;