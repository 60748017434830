
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Tapping climate finance to mobilize resources in education";
export const lead = "Integrating education into climate finance initiatives may enhance the capacity for adaptation and mitigation efforts.";
export const layout = "focus";
export const article = "finance";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Tapping climate finance to mobilize resources in education" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=311" mdxType="FocusDetails">
      <h1 {...{
        "id": "tapping-climate-finance-to-mobilize-resources-in-education"
      }}>{`Tapping climate finance to mobilize resources in education`}</h1>
      <p>{`In recent years, like other human activities, the education sector has felt the effects of climate change. Rising temperatures, more frequent and severe natural disasters, and other climate-related challenges create obstacles for schools and students. Excessive heat poses significant health risks and often leads to school closures, resulting in missed educational opportunities. Climate-related disasters such as flooding and droughts, cyclones, hurricanes, tornadoes, landslides, and wildfires jeopardize students’ safety and well-being. `}</p>
      <p>{`Many low- and lower-middle-income countries are particularly vulnerable to the impacts of extreme weather events (Eckstein et al., 2021). In 2022, excessive flooding in Pakistan disrupted schooling for 3.5 million children and destroyed more than 26,000 schools (Imran, 2022). Somalia has been impacted by severe drought, resulting in population displacement. More than 80 schools in the states of Jubaland and Galmudug were closed as of April 2022 as a result (Internal Displacement Monitoring Centre, 2023). The number of medium- to large-scale disasters is projected to increase from 400 annually in 2015 to 560, or about 1.5 each day, by 2030 (UNDRR, 2023). `}</p>
      <p>{`Education can play a critical role in the response to climate change, especially through the inclusion of climate change content in curricula. The investment is not costly and can, in principle, be covered by education budgets. But climate finance presents an opportunity to support large-scale investments in school infrastructure to help schools withstand climate events. Integrating education into climate finance initiatives may also enhance the capacity for adaptation and mitigation efforts.`}</p>
      <h2 {...{
        "id": "a-brief-history-of-climate-finance"
      }}>{`A brief history of climate finance`}</h2>
      <p>{`Climate finance emerged in response to the growing recognition of climate change as a critical global issue. The concept gained traction with the 1992 Earth Summit, where the United Nations Framework Convention on Climate Change acknowledged the need for financial resources to support climate efforts (UNDRR, 2023, 2023; United Nations Framework Convention on Climate Change, 1992). The Kyoto Protocol, adopted in 1997, marked a significant step by introducing mechanisms which facilitated investments in emissions-reduction projects in developing countries (UNFCCC, 1997).`}</p>
      <p>{`As climate finance evolved, key milestones included the establishment of the Global Environment Facility in 1991 and the Climate Investment Funds in 2008 (CIF, 2024; Global Environment Facility, 2024). The 2009 Copenhagen Accord was pivotal, committing developed nations to mobilize USD 100 billion annually by 2020, leading to the creation of the Green Climate Fund in 2010. The 2015 Paris Agreement further reinforced these commitments and emphasized the need to enhance developing countries’ capacity (Paris Agreement, 2015).`}</p>
      <p>{`The most recent development which specifically targeted education occurred at the 2023 United Nations Climate Change Conference or Conference of the Parties of the United Nations Framework Convention on Climate Change, more commonly known as COP28, where 45 countries endorsed the Declaration on the Common Agenda for Education and Climate Change. This declaration calls for nations to adapt, mitigate and invest in building climate-smart education systems through the four pillars of action of the Greening Education Partnership: greening schools, curricula, teacher training and community engagement (UNESCO, 2024). `}</p>
      <p>{`The 2023 Global Landscape of Climate Finance Report reveals that average annual climate finance flows reached almost USD 1.3 trillion in 2021/22, nearly double the 2019/20 levels. This increase was primarily driven by a significant acceleration in mitigation finance (which accounts for 91% of the total). Government climate finance commitments have surged to USD 288 billion, a significant rise from USD 179 billion in 2021. Notably, only USD 33 billion was directed internationally, with two thirds, or USD 21 billion, flowing from OECD to non-OECD countries (IDFC, 2022, 2024). Even with the recent surge in climate finance, only a small fraction is recorded as ODA. According to the OECD CRS, over USD 900 million were disbursed through major climate financing facilities and instruments in 2022, a growth of 350% since 2015. However, the education sector received just USD 13 million for climate finance initiatives.`}</p>
      <FocusImage size="large" src={"37-climate_fin.png"} alt="Climate finance is estimated at USD 1.3 trillion but education gets a tiny fraction" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=312" mdxType="FocusImage" />
      <h2 {...{
        "id": "countries-face-challenges-and-opportunities-in-accessing-climate-funds-for-education"
      }}>{`Countries face challenges and opportunities in accessing climate funds for education`}</h2>
      <p>{`Climate finance is typically managed by entities outside the education sector, so education ministries can find it difficult to access these funds directly. Effective access to climate finance hinges on several key components. First, a national climate plan must clearly outline the education sector’s role in climate preparedness and establish a coordination mechanism among various stakeholders. This plan should define the responsibilities of the education ministry and other relevant ministries to ensure robust disaster risk management and secure adequate resource allocation for the education sector. Incorporating disaster risk considerations into budgetary processes is essential to support resilience-building efforts effectively across line ministries (Southerland et al., 2024). However, national plans often overlook the education sector’s importance in climate finance. The education sector also requires improved capacity for risk assessment and climate planning to effectively contribute to these efforts.`}</p>
      <p>{`Various countries show examples of education leveraging climate finance. In Grenada, efforts to enhance resource allocation for climate resilience are underway, with a focus on increasing the number of schools with disaster management plans. While government agencies are encouraged to design their own continuity and emergency response plans, recurrent funding for disasters is primarily allocated to the National Disaster Management Agency. Strengthening the coordination within the Ministry of Education for preparedness planning and providing clearer guidance for schools on disaster plan preparation are essential steps. Dedicated staff with the necessary expertise can further enhance these efforts. A clearly defined role for the Ministry of Education within a coordinated national mechanism is crucial for effective and equitable resource mobilization in climate finance (Grenada Ministry of Education, 2023).`}</p>
      <p>{`Thailand, which faces significant climate risks, has developed a National Adaptation Plan that incorporates climate education into its strategy. This plan outlines a coordination mechanism for accessing international funds, ensuring budgetary support for government agencies and establishing integrated budgeting for climate change actions. The Department of Climate Change and Environment serves as the focal point for cross-sectoral coordination of adaptation monitoring and evaluation (UNFCCC, 2024). While the plan’s current focus is stronger in sectors such as health and agriculture, there is potential to enhance the role of education, which could further secure resources for educational initiatives.`}</p>
      <p>{`Second, effective access to climate finance requires a dedicated national agency to bridge international and domestic funding sources. Such an agency can align national goals with global funding opportunities, ensuring the education sector’s needs are well integrated into broader climate finance initiatives. Accessing climate finance from entities like the Green Climate Fund and the Global Environment Facility can be challenging for developing countries due to complex processes and stringent criteria. Detailed project proposals must meet specific requirements, often involving multiple project cycles and coordination with various entities, such as the national designated authority and focal points for these funds. Moreover, securing funding from development banks entails compliance with diverse criteria, adding to administrative burdens.`}</p>
      <p>{`Kenya is demonstrating a proactive approach to climate finance in sub-Saharan Africa. With national adaptation plans in place and support from development partners, Kenya has established a national designated agency for the Global Climate Fund. It also has two national accredited entities, the National Environment Management Authority of Kenya and KCB Bank Kenya Limited, to implement projects (Green Climate Fund, 2023a). To enhance green finance delivery, the Treasury conducted a public expenditure and budget review, leveraging public financial management systems (Kenya National Treasury, 2017). Priority areas include water and the blue economy, forestry, wildlife, tourism, disaster risk management, and food and nutrition security. While education is not explicitly highlighted, community-led sanitation, which may include schools, and capacity-building projects receive external support (Kazimbaya-Senkwe, 2024; World Bank, 2021d). `}</p>
      <p>{`Rwanda’s National Environment Fund serves as a national financing mechanism for climate change and environmental projects. Established by the government, the Fund channels, programmes, disburses and monitors climate finance. Acting as a national basket fund, pooling resources from various contributors, it facilitates direct access to international funds such as the Green Climate Fund, streamlining the integration of external aid and domestic finance. The fund is accessible to line ministries, districts, private entities and civil society, supporting projects that align with Rwanda’s goals for sustainable, climate-resilient and green economic growth (Rwanda Government, 2022; Rwanda Green Fund, 2022).`}</p>
      <p>{`Third, while international finance often supports projects on a case-by-case basis, a comprehensive approach is essential for maximizing risk preparedness. Addressing system-wide aspects through broader frameworks and engaging diverse stakeholders is key. For example, the Global Partnership for Education’s Climate Smart Education System initiative aims to improve readiness to access climate finance for up to 35 of the most climate-vulnerable countries, in turn developing a viable pipeline for future investments between 2024 and 2026. The initiative supports education ministries to access climate finance to integrate climate change into the sector. It provides technical assistance for evidence-based planning, cross-ministerial coordination and building greener, more resilient education infrastructure (GPE, 2024a). `}</p>
      <p>{`The World Bank’s Global Facility for Disaster Reduction and Recovery emphasizes cross-sectoral involvement and the establishment of a comprehensive framework for disaster risk reduction in education. Its Global Program for Safer Schools integrates technical advice, risk-informed investment designs and risk reduction considerations into education infrastructure. Between 2014 and 2023, the programme supported 35 countries and 564,000 schools, influencing the design and implementation of over USD 3.1 billion worth of school infrastructure projects (World Bank, 2019, 2024). `}</p>
      <p>{`The World Bank also supports the Pacific Safer Schools Program, which collaborates with governments, the construction industry and non-governmental organizations in Samoa, Tonga and Vanuatu to reinforce school buildings and infrastructure (Cordero, 2024; Kleymeyer, 2017; World Bank, 2022, 2024). In 2024, the World Bank approved a USD 1.25 billion loan for resilient schools in the Philippines, with USD 500 million dedicated to supporting the recovery of disaster-affected schools in selected regions. `}</p>
      <p>{`Finally, effective risk assessment and cost estimation are crucial for the education sector to comprehensively address its needs. Resources for capital investment should be clearly outlined in education sector plans that incorporate disaster preparedness and management. Belize’s Education Sector Plan 2021–2025 addresses the significant risks posed by hurricanes and droughts, emphasizing the need for hurricane-resistant facilities and effective risk mitigation strategies. The plan highlights the importance of allocating sufficient capital for achieving climate resilience objectives: 89% of the education budget is dedicated to staff costs, with the remaining 11% for training, scholarships, facility maintenance and materials. This underscores the challenge of securing adequate funding for capital expenditures Belize Ministry of Education, Culture, Science and Technology, 2021). `}</p>
      <p>{`To address similar challenges globally, initiatives like Building the Climate Resilience of Children and Communities through the Education Sector (BRACE) have been launched. This initiative is based on the Climate Smart Education System initiative and exclusively finances the education sector. BRACE focuses on increasing the resilience of education systems by retrofitting and constructing climate-adaptive schools in countries such as Cambodia, South Sudan and Tonga. It aligns with the international School Safety Framework and is implemented in collaboration with UNESCO, its International Institute for Educational Planning and Save the Children (GADRRRES, 2022; GPE, 2023; Green Climate Fund, 2023b, 2023c).`}</p>
      <p>{`Climate finance offers valuable opportunities to enhance disaster preparedness and resilience within the education sector. Accessing climate finance can be challenging, but overcoming these obstacles is feasible and can lead to substantial benefits. Initiatives like BRACE demonstrate how targeted investments can strengthen education systems and support sustainable development in vulnerable regions.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;