
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Climate change education aims to equip populations to cope with and mitigiate the effects of climate change";
export const lead = "Climate change education (CCE) can be formal, non-formal or informal, multidisciplinary and at every education level; it aims to help populations understand, address, mitigate and adapt to the impact of climate change.";
export const layout = "focus";
export const article = "learning";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Climate change education aims to equip populations to cope with and mitigiate the effects of climate change" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=332" mdxType="FocusDetails">
      <h1 {...{
        "id": "climate-change-education-aims-to-equip-populations-to-cope-with-and-mitigiate-the-effects-of-climate-change"
      }}>{`Climate change education aims to equip populations to cope with and mitigiate the effects of climate change`}</h1>
      <p>{`Climate change education (CCE) can be formal, non-formal or informal, multidisciplinary and at every education level; it aims to help populations understand, address, mitigate and adapt to the impact of climate change. Achieving this requires developing appropriate curricula, teacher training and pedagogies (UNESCO and UNFCCC, 2016). CCE encourages positive mindsets for lasting change and actions to address the causes of climate change and adopt more sustainable lifestyles. It also aims to help policymakers become fully aware of the urgent need for mechanisms to tackle climate change at the national and global levels and to increase the resilience of the vulnerable communities that are the most likely to be affected (UNESCO, 2015b). CCE participants are more concerned about environmental issues (UNESCO, 2021c), which often translates into support for pro-environmental policies, environmental activism and active participation (Coan and Holman, 2008; Lubell et al., 2006).`}</p>
      <p>{`Demand for CCE is clearly expressed in public opinion surveys. Global surveys show that most people are concerned about climate change, are willing to change 2021/2 • GLOBAL EDUCATION MONITORING REPORT32016
their lifestyles to mitigate its effects and support further relevant government action. A 2020 survey showed widespread recognition of the urgent need for a collective approach by government and civil society to advance the agenda to address climate change (World Economic Forum, 2020). The results also indicated that more than half of global respondents, particularly in South Asia, had full confidence in climate science. An overwhelming majority of respondents worldwide indicated they saw most government climate regulations as necessary.`}</p>
      <p>{`A 2021 Pew Research Center survey of more than 16,000 adults in 17 high-income countries found that between 60% and 90% felt somewhat or very concerned about damage they might personally experience as a result of climate change, a marked increase since the original survey conducted in 2015 (Luong et al., 2021). In 16 countries, at least 70% of respondents said they were willing to make some or many changes to their lifestyle to help tackle global climate change, with higher willingness among younger respondents. However, deep ideological divides in climate attitudes were evident in Australia, Canada, the Kingdom of the Netherlands and the United States. Such divides are likely to pose significant challenges for implementation of much-needed policy changes, including in education.`}</p>
      <p>{`Despite concern, many countries are still not fully committed to making climate action a core component of the curriculum. Results of the 2019 curriculum analysis of the Fourth Regional Comparative and Explanatory Study in Latin America and the Caribbean revealed that while more than half the 18 countries analysed mentioned climate change in their curriculum documents, it was only the 11th-most-covered Education for Sustainable Development theme (UNESCO, 2020). Similarly, a UNESCO study revealed that climate change was mentioned in less than half the policy and curricula documents examined (UNESCO, 2021c). However, the 2018 PISA survey reported that of the top seven global issues commonly included in curricula in OECD countries, the one most commonly reported by school principals was global warming and climate change. The study indicated that 88% of students in OECD countries attended a school where the topic was covered in the curriculum (OECD, 2020).`}</p>
      <p>{`A few countries are adopting promising education changes to ensure CCE of good quality. Countries are making significant efforts to address CCE in a cross-curricular rather than subject-specific way. In France, the Vademecum on Education for Sustainable Development stated that at the start of the 2020/21 school year, the curricula for cycles 1, 2, 3 and 4 had been strengthened with lessons on climate change, in art and music education, French, moral and civic education, history and geography, the  life and earth sciences, technology, modern languages, physics and chemistry.`}</p>
      <p>{`In Italy, climate change education is part of transversal civics education introduced by law to schools from 2020/21 to support development of knowledge and understanding of social, economic, legal, civic and environmental structures. In the Republic of Korea, the national curriculum framework introduced climate change education at all levels in 2007. In preschool, climate change education is part of scientific exploration. This curriculum encourages 4-year-olds to engage with weather and climate change and 5-year-olds to learn about climate regularities. The framework is anchored in the Environmental Education Promotion Act, which aims to contribute to sustainable development by fostering knowledge and skills to prevent and address environmental problems, including climate change (Republic of Korea Ministry of Education, 2015).`}</p>
      <p>{`In India, educational resources are being developed to integrate climate change topics into core curricula at the school and undergraduate levels to raise awareness about the causes and effects of climate change. A team has built a repository of teaching resources from around the world on climate change that can be used for discipline-specific topics in mathematics, science, humanities and the social sciences. This new pedagogical approach of integrating CCE into the existing curriculum allows students to develop analytical and communication skills in core subjects (Shashidhara, 2019).`}</p>
      <p>{`Climate change can evoke emotions such as anxiety, empathy and anger, which influence action. However, few countries put emphasis on non-cognitive dimensions such as social and emotional components and values. In Rwanda, the 2015 national curriculum framework integrates climate change, environment and sustainability into learning materials that are also meant to develop values for sustainable living, an intention confirmed in the 2019–24 Education Sector Strategic Plan.`}</p>
      <p>{`Many countries have incorporated CCE in their curricula, both in theory and practice (Chiba et al., 2021), with solution-oriented and participatory approaches to empower learners to be agents of change (UNESCO, 2015b). Successful approaches in Erasmus+ projects on education for sustainable development often include physical activities, such as collecting waste, planting trees and organizing environmental campaigns (European Commission, 2021). Since the mid-1990s, Colombia’s Ministry of National Education has managed School Environmental Projects that identify environmental priority situations and focus on actions adapted to school realities, integrating various fields of knowledge, disciplines and skills for interdisciplinary problem solving (Colombia Ministry of National Education, 2005; Mora-Ortiz, 2015).`}</p>
      <p>{`Many countries have incorporated CCE in their curricula, both in theory and practice (Chiba et al., 2021), with solution-oriented and participatory approaches to empower learners to be agents of change (UNESCO, 2015b). Successful approaches in Erasmus+ projects on education for sustainable development often include physical activities, such as collecting waste, planting trees and organizing environmental campaigns (European Commission, 2021). Since the mid-1990s, Colombia’s Ministry of National Education has managed School Environmental Projects that identify environmental priority situations and focus on actions adapted to school realities, integrating various fields of knowledge, disciplines and skills for interdisciplinary problem solving (Colombia Ministry of National Education, 2005; Mora-Ortiz, 2015).`}</p>
      <p>{`Strong connections to the environment and climate result in more intentions to act and willingness to change behaviour (Dietz et al., 2020; Zelenika et al., 2018). Hence countries try to prioritize action-oriented teaching and place-based learning, especially in upper secondary education (UNESCO, 2021c). In England (United Kingdom), the youth-led Teach the Future campaign aims for all school buildings to have a net-zero carbon footprint by 2030. To this end, the Department for Education is providing funding to help schools become more sustainable (Burns, 2020).`}</p>
      <p>{`Overall, in terms of pedagogy, an effective CCE approach would aim to achieve a balance between developing learners who can think critically about CCE and empathetic students who are committed to taking action to improve their living environment (Chang, 2015). It would promote collaboration with peers and interactions to inspire change. Promising initiatives require a long-term perspective to be able to trigger behaviour change. There is little robust cross-national information on where countries stand on addressing climate change in education systems, as much of the information appears in national documents rather than in Nationally Determined Contributions to the UN Framework Convention on Climate Change Secretariat (SEPN, 2020).`}</p>
      <p>{`A new series of country profiles on climate change communication and education at the Profiles Enhancing Education Reviews (PEER) website aims to improve the evidence basis. It is the result of a partnership between the Global Education Monitoring Report and the Monitoring and Evaluating Climate Communication and Education (MECCE) project, hosted by the Sustainability and Education Policy Network and financed by the Social Sciences and Humanities Research Council of Canada. It offers a comparative perspective of countries’ progress on Article 6 of the United Nations Framework Convention on Climate Change and Article 12 of the Paris Agreement (Action for Climate Empowerment), and SDG target 4.7. A first set of 20 country profiles was published in November 2021, covering all world regions and country income groups. A second set of up to 50 profiles is scheduled to be published in 2022.`}</p>
      <p>{`These profiles present content on climate change contexts; climate change education (policy, curriculum, teacher education and assessment) in primary, secondary, technical, vocational, higher, teacher and adult education; climate change communication (public awareness, public access to education, public participation); and monitoring. Initial analysis suggests that in all but 1 of the 20 countries analysed, education ministries work on climate change; all but 2 have a national climate change law, strategy or plan that includes education content; and all but 3 have monitoring mechanisms to track progress in CCE. Yet a climate change focus was found in only 40% of national education laws and 45% of education sector plans or strategies. National curriculum frameworks refer to climate change in 75% of countries and just over one third have a law or plan with a clear focus on CCE. Efforts focus on the primary and secondary education levels in 90% of countries; on technical, vocational and higher education in 70%; and on teacher education in 55%.`}</p>
      <FocusImage size="medium" src={"18-climatechange.png"} alt="There is scope for countries to strengthen how climate change is addressed in education" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=335" mdxType="FocusImage" />
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=557"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;