
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "What do online searches reveal about interest in international scholarships?";
export const lead = "Monitoring scholarships suffers from a lack of robust data.";
export const layout = "focus";
export const article = "finance";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="What do online searches reveal about interest in international scholarships?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=317" mdxType="FocusDetails">
      <h1 {...{
        "id": "what-do-online-searches-reveal-about-interest-in-international-scholarships"
      }}>{`What do online searches reveal about interest in international scholarships?`}</h1>
      <p>{`More and more people rely on the internet for an increasing number of activities, including those related to work and education. Accordingly, insights can be gained from data collected about these activities. For example, the rise and fall in the number of people searching online for flu symptoms might track flu incidence. This premise has given rise to hundreds of studies using Google Trends data alone (Jun et al., 2018), just one data source among many. Studies have employed various forms of online or mobile phone data, including to estimate macroeconomic indicators (Narita and Yin, 2018) or small-area illiteracy estimates in Senegal (Schmid et al., 2017). The 2021 World Development Report, which focused on data, called for the use of alternative data sources to monitor public health, target resources and service delivery, analyse information not collected by the government (such as access to financial services), and hold governments accountable, while noting limitations in terms of representativeness and risks of discrimination and manipulation more precisely.`}</p>
      <p>{`Despite a proliferation of research of this nature exploring questions relating to international development and migration, hardly any of these studies focus on student migration and none on international scholarships or scholarship holders. This seems a missed opportunity to gain insights into the interest in international scholarships around the world.`}</p>
      <p>{`Monitoring scholarships suffers from a lack of robust data. There is no consensus on how to provide standardized, transparently defined and comprehensive information on the number of international scholarships available to students from low- and middle-income countries. In the absence of such standard data, the GEM Report team has conducted experimental research, albeit with careful attention to methodology, analysis and interpretation.`}</p>
      <p>{`The first step to identifying an overseas scholarship opportunity is often an online search, using general search engines such as Google or Baidu; their searches are highly correlated in China, although Baidu accounts for a vastly higher volume (Vaughan and Chen, 2015). Specialized scholarship platforms provide an alternative, or complementary, tool for some students, while others rely on low-tech information sources, such as word-of-mouth. Still, patterns of online searches for international scholarship opportunities using general search engines may unveil broad trends.`}</p>
      <p>{`The report team analysed the volume of searches related to international scholarships on the Google search engine. Trends in searches for given keywords are publicly available through the Google Trends portal. What Google provides is not the absolute number of searches but search interest relative to a benchmark. For trend analysis, the benchmark (scaled to an index value of 100) is the highest number of daily searches during the period in question. For comparisons between search terms, the benchmark is the highest among them.`}</p>
      <p>{`Easy access and the high share of Google in the online search market are general advantages that explain the popularity of these data. However, the market share and representativeness vary greatly across countries. And while searches in other languages are covered in principle, in practice only English language searches provide a sufficient sample size for analysis for many search terms. Moreover, the underlying data are proprietary, and the methodological choices are opaque. Care must be taken with how the data are extracted and interpreted.`}</p>
      <p>{`Some patterns are predictable. For example, students receive their grades and the diplomas they need to apply for scholarships at specific points in time. Most scholarships have application deadlines. Even if the exact timing of these markers differs by country or even institution, a seasonal pattern could be expected that repeats each academic year. In 2020 and 2021, however, a pronounced drop could be predicted, as travel opportunities were curtailed by the COVID-19 pandemic. It is also a recognized fact that international student mobility is influenced by historic, cultural and especially linguistic proximity. Accordingly, search interest in scholarships for study in the United Kingdom could be assumed to be higher in an anglophone African country than in its francophone neighbour, for instance.`}</p>
      <p>{`Indeed, the African countries where ‘international scholarships UK’ had the highest index value over the past five years are Ghana, Kenya, Nigeria, South Africa and Uganda. This contrasts with the equivalent French term ‘bourse en France’, which attracted the most interest in Benin, Cameroon, Congo, Côte d’Ivoire, Madagascar and Senegal. Search activity for the United Kingdom–based Chevening scholarship displays a pronounced period of heightened interest from approximately June to October, and drops off precipitously in early November after the application deadline.`}</p>
      <p>{`Despite these signs that search engine analysis may be promising, there are several caveats. Google Trends data themselves fluctuate too much (Fenga, 2020). For example, identical searches for the same past time period do not give identical results when conducted on different days (Cebrián and Domenech, 2022). This is because Google Trends uses a random sample of internet protocol (IP) addresses (Böhme, 2020). While this variation may be large enough to make trend analysis unreliable for short timescales (Behnen et al., 2020), it is unlikely to influence the results of looking at large trends over months and years. All figures display the average search index values over multiple queries.`}</p>
      <p>{`More problematic is that it has been observed that completely unrelated search terms share common trends in Google Trends data (Bokelmann and Lessmann, 2019), suggesting spurious patterns driven by changes in overall search volume or methodological changes. This is reported to have been the most pronounced in the 2000s, when the service was relatively new and the online search market more dynamic. Because of this, the analysis is limited to the period since 2010.`}</p>
      <p>{`Trends were analysed for the generic search term ‘international scholarships’ as well as for scholarships in the United States, Germany as the major non-anglophone European destination, and emerging destinations such as China and Türkiye (reflecting the country’s name at that time, prior to its official change to Türkiye). Each series is normalized independently, meaning that the values are not directly comparable between series. In particular, it would be a mistake to interpret the graphs as showing the same number of searches, when, for example, searches for Türkiye were about half as common as searches for the United States. At the same scale, destination-agnostic searches outnumber those specific to the United States as a destination by a factor of 10, and searches for Türkiye as a scholarship destination by a factor of 100.`}</p>
      <p>{`For the generic search, a long period of stability can be seen in 2010–19, followed by a visible, if moderate, drop in early 2020 in response to COVID-19 uncertainty and travel restrictions, and by a sharp increase afterwards, even exceeding pre-pandemic levels. However, the most recent data are difficult to interpret due to changes in Google’s data collection in early 2022.`}</p>
      <FocusImage size="small" src={"27-onlinesearch_1.png"} alt="Since the onset of the pandemic, online search interest in international scholarships has increased sharply" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=318" mdxType="FocusImage" />
      <p>{`For scholarships to the United States, a previous upward trend reversed following the election of Donald Trump as US President in 2016. This matches official data on new international student enrolment, that increased by 50% from around 200,000 in 2009/10 to 300,000 in 2015/16, but then steadily declined by a total of 10% up to the academic year that started in autumn 2019 (OpenDoors, 2023). Searches for scholarships in Türkiye increased rapidly from 2010 to 2014 but declined relatively steadily until 2020 before picking up again in 2020–21. Searches for China did not recover from the pandemic decline until now, unlike for traditional destinations.`}</p>
      <p>{`An alternative to analysing search terms is to examine how advertising algorithms classify internet users (Zagheni et al., 2017). Facebook’s Advertising Manager was developed to improve the accuracy of audience targeting. It can display an estimate of the number of individuals meeting certain criteria explicitly reported by Facebook users (such as being between 18 and 24 years old and being an upper secondary school graduate) in combination with classifications inferred by Facebook from their activities and interactions on the internet, such as having an interest in a certain topic (for example, international scholarships).`}</p>
      <p>{`A study validating Facebook’s data against selfreporting results in an online survey with respondents recruited through targeted Facebook advertisements indicated good overall accuracy of demographic classification (Grow et al., 2022). However, the age group 18-24 was misclassified relatively often, perhaps because respondents had made themselves older at the time of initially joining the platform. With respect to inferred interest, the underlying algorithm is proprietary and not transparent. The resulting estimates, therefore, cannot be taken at face value. However, the fact that uncertainty is recognized, and a range provided, increases credibility.`}</p>
      <p>{`With these caveats in mind, Facebook’s estimates of 18- to 24-year-olds with upper secondary school certificates and an interest in international scholarships are charted in various countries alongside the number enrolled in tertiary education or studying abroad. Under the assumption that the estimates are of the right order of magnitude, two conclusions can be drawn. On the one hand, only a small fraction of all students, and even fewer upper secondary school graduates, in low- and middle-income countries display an interest in international scholarships in their online behaviour. On the other hand, despite being a relatively small group, the number of those who are interested by far exceeds the availability of scholarships. The 2020 GEM Report estimated that the top 50 scholarship providers offered only around 30,000 new scholarships in 2019; yet, in Uganda alone, almost 100,000 youth were considered to be among those ‘interested’ in a scholarship.`}</p>
      <FocusImage size="small" src={"27-onlinesearch_2.png"} alt="Facebook’s estimate of its users interested in international scholarships typically far exceeds the number of students already abroad" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=319" mdxType="FocusImage" />
      <p>{`To respect both privacy and sample size concerns, neither Google nor Facebook publish estimates that are too small. On Google Trends, the search index for a given keyword and matching country is not published if the number of searches falls below a certain threshold. Facebook Advertising data do not provide estimates for audiences smaller than 1,000, precluding its use for looking for scholarship holders from specific countries (Fatehkia et al., 2022).`}</p>
      <p>{`Most problematically, data on online behaviour are almost entirely proprietary. Some data are made available to the public, some through ad hoc requests and some are commercially available for marketing purposes. In any case, data availability is at the discretion of the companies owning the data. The data themselves are, at best, calibrated internally, but there is no outside validation, at least none that is publicly available. It can only be assumed, without knowing for certain, that an increase in online searches for the term ‘scholarships UK’ indicates an increase in actual interest. Even less is known about increases in scholarship applications. Academic research suggests that the intention to migrate internationally does affect migration overall, albeit in a complex interplay with diverse factors such as opportunities, social capital, skills and information that vary between individuals (Wanner, 2021). This experimental analysis does not try to predict actual scholarship mobility. Non-traditional data sources show some promise where official data are not forthcoming. However, many obstacles prevent satisfactory estimates from Google Trends (Leysen and Verhaeghe, 2022). The analysis presented here only scratches the surface, and the limitations must be noted, such as results relying upon the selection of an exact search term. `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;