
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Scholarship aid flows are becoming less concentrated";
export const lead = "Scholarship aid flows represent a network of connections between donor and recipient countries.";
export const layout = "focus";
export const article = "finance";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Scholarship aid flows are becoming less concentrated" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=356" mdxType="FocusDetails">
      <h1 {...{
        "id": "scholarship-aid-flows-are-becoming-less-concentrated"
      }}>{`Scholarship aid flows are becoming less concentrated`}</h1>
      <p>{`Scholarship aid flows represent a network of connections between donor and recipient countries. Network analysis, a research field that traces relations between actors, can shed light on how the spatial pattern of scholarship aid flows relates to flows of international students, and to geographical, trade, cultural and historical links (Shields and Menashy, 2019).`}</p>
      <p>{`The more equal the number of connections among actors, the less centralized the network. By several measures, the scholarship aid network became less centralized in the 2010s and flows have become more dispersed, i.e. more evenly distributed among recipients. In conjunction with the SDG targets, this trend may also be explained by an increasing role of scholarships in cultural diplomacy and the wider exercise of soft power (Campbell and Neff, 2020). Still, recorded scholarship aid flows represent only around 10% of all possible connections between donors and recipients.`}</p>
      <p>{`Three groups of donors can be identified. Some report scholarship aid to only one or two countries, perhaps reflecting key bilateral relationships. Another group sends aid to some 10 to 20 countries, perhaps due to regional or historical connections. The third group provides scholarship aid to over 20 countries, reflecting a broad or even near universal approach that likely extends beyond key bilateral relationships. Donors are now likely to provide scholarships to more developing countries than in 2015, and, more importantly, recipient countries are less likely to be dependent on one or two key donor.`}</p>
      <p>{`Networks also exist among donors, with the strength of a tie between two donors defined by the number of common countries to which they provide scholarship aid. This is similar for recipients and their number of shared donors. Both donor and recipient networks suggest that aid flows have become generally less clustered.Some geographical clustering is still evident. The Czech Republic, Hungary, Poland and Romania provide scholarship aid to a similar set of countries. Among the recipients, Armenia, Belarus, Georgia and Ukraine have many donors in common.`}</p>
      <p>{`Diversification of recipients can reflect deliberate policy. China’s provision of scholarships to Africa started in 1972 with 200 scholarships for students from the United Republic of Tanzania and Zambia (Dong and Chapman, 2008). The government has since considerably increased the number – in 2018, it issued over 63,000 international higher education scholarships (Jing, 2020) – and diversified recipients’ nationality. Between 1999 and 2015, nearly all African countries received Chinese government scholarships for students to pursue higher education in China, and 14 countries had over 1,500 recipients each (Ha et al., 2020).`}</p>
      <p>{`Chinese scholarship programmes are also open to applicants from any developing country, including specific scholarships for nationals of countries in the Association of Southeast Asian Nations and the European Union, along with Pacific Island states (China Scholarship Council, 2020). Still, bilateral trade and diplomatic relations are strong predictors of China’s international scholarship recipients’ nationalities (Ha et al., 2020).`}</p>
      <p>{`Indeed, analysis of how bilateral scholarship aid relates to other networks shows that it tends to be more frequent between countries where international student mobility is generally higher, to countries where donors export goods or services, and to former colonies. The direction of some of these associations is ambiguous. For example, increased scholarship aid may be both an outcome of high levels of student mobility and a contributing factor. The continued association between scholarship aid and past colonial ties should prompt critical discussions on the decolonization of higher education. In some cases, the link is intentional, as with Commonwealth Scholarships offered by the United Kingdom. By contrast, Türkiye explicitly views its lack of colonial history as an asset in expanding its ties in Africa (Ünveren, 2021), including by providing scholarships.`}</p>
      <p>{`Another interesting question is which recipient countries receive scholarship aid above what would be expected from their position in trade, mobility, cultural and other networks. Some of the least developed countries, including Mali and Mauritania, along with Small Island Developing States such as Comoros and Samoa, attract relatively high scholarship aid.`}</p>
      <p>{`Overall, application of social network perspectives provides greater insights into scholarship aid trends than would be possible through aggregated trend analysis alone. The social network between donors and recipients is complex and changing rapidly. While the analysis shows that scholarship aid is still connected to trade and historical colonial ties, it also shows that recipient countries have access to aid from an increasing number of donors. This trend represents at least qualitative progress towards target 4.b.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=563"} mdxType="ReferenceButton">
  2021 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;