
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Can leadership be taught?";
export const lead = "Whether leadership can be taught is an old debate reflecting the question of nature vs nature.";
export const layout = "focus";
export const article = "learning";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Can leadership be taught?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=220" mdxType="FocusDetails">
      <h1 {...{
        "id": "can-leadership-be-taught"
      }}>{`Can leadership be taught?`}</h1>
      <p>{`Whether leadership can be taught is an old debate  (Brooks et al., 2019; Channing, 2020; Doh, 2003), reflecting the question of nature vs nature: are leaders born or made? Some argue that skills commonly associated with leadership, such as initiative, assertiveness and charisma, are innate qualities that cannot be acquired through formal education. They are impacted by factors such as social context, parental socioeconomic status and parenting behaviour (Duan et al., 2022; Murphy and Johnson, 2011).`}</p>
      <p>{`But leadership skills and behaviours can indeed be taught (Channing, 2020). They can change and grow as a function of particular developmental activities and experiences (Murphy and Johnson, 2011; Zaccaro et al., 2018). Practical skills such as clear argument, oral expression and good communication can successfully and effectively be taught through formal education. Even personality traits, such as conscientiousness, emotional stability, extraversion, agreeableness and openness to experience (Chioda et al., 2023; Kankaraš and Suarez-Alvarez, 2019), which have shown to have a strong and consistent correlation with leadership (Bono and Judge, 2004), have proved to be malleable and responsive to interventions.`}</p>
      <p>{`It has often been assumed that teaching leadership is more effective for those that have already acquired some experience in organizational settings (Grint, 2007). As leadership skills have a practical hands-on orientation, it may be appropriate for learners to already have some experience in work settings or group dynamics. But leadership skills training, which originated from  such on-the-job managerial training, gradually became part of formal programmes in higher education  institutions, particularly in business education programmes (Brooks et al., 2019; Colombo and Piva, 2020). Some of the skills developed by these programmes are decision making, goal setting, empathy, self-awareness and interpersonal communication (Lee and Chan, 2023). `}</p>
      <p>{`Entrepreneurship programmes that incorporate the development of leadership skills have been shown to foster entrepreneurship intentions and behaviours (Colombo and Piva, 2020; von Graevenitz et al., 2010). A study of higher education students in Jordan who took entrepreneurship classes showed that their knowledge and orientation towards entrepreneurship increased significantly (Alakaleek et al., 2023). However, traditional business education is critiqued in that a rigorous conceptual and analytical approach to training may be needed but not at the cost of practical orientation such as internships, on-the-job training, coaching and mentoring. There is consensus that traditional leadership programmes have struggled to prepare leaders for work environments (Elmuti et al., 2005).`}</p>
      <p>{`As well as leadership training for adults, there have been efforts to develop leadership skills among children and youth (Dempster and Lizzio, 2007; Wright et al., 2023). From a lifelong perspective, fostering leadership skills is a process that starts during the early years, in which formal education can play a pivotal role (Elmuti et al., 2005;  Lee and Chan, 2023). School settings are a good location to build leadership skills, as they are the primary and most important organizational experience throughout childhood. Leadership skills can be fostered through decision making, getting along with others, self-awareness and working with groups (Karagianni and Montgomery, 2018). Students can be exposed to leadership skills beyond the narrow confines of any specific subject. Participation in any kind of organized group activity, such as choirs, musical groups, theatre groups, scouts and sports clubs, may provide invaluable opportunities for learning and practising leadership skills (Duan et al., 2022). `}</p>
      <p>{`Displays of leadership at the school level have been linked to positive subsequent outcomes. In Zambia, school girls that participated in negotiation training had better outcomes three years after the intervention, such as participating in national examination, scoring at the top 25% of these examinations, and avoiding pregnancy until after grade 11 (Ashraf et al., 2020). Adolescent leaders are more likely to end up in managerial positions as adults, while leadership skills developed early have been found to have a positive impact on future wages (Kuhn and Weinberger, 2005).`}</p>
      <p>{`Investment in leadership training in schools goes beyond just preparing students to be business leaders. Programmes, especially those promoted by non-governmental organizations, seek to prepare students to be civic engagement leaders, climate education advocates and minority rights defenders. Interventions in Uganda seeking to empower school girls through vocational training and information on sex, marriage and reproduction found that four years after the intervention they were more likely to be self-employed and delay family formation (Bandiera et al., 2020). A study of global citizenship education curricula in Central America found that half of the beneficiaries had increased their participation in volunteering and social entrepreneurship activities and had been able to create civic awareness through developing networks, groups and communities of practice (UNESCO et al., 2021)`}</p>
      <p>{`An issue that is often absent from programme design is that leadership skills may be unequally distributed in the population. For example, assertiveness – the capacity to take the lead and voice opinions – can boost leadership skills. While measuring assertiveness presents technical challenges, one such attempt in 5 countries and 17 cities found considerable differences between students from less and more socioeconomically disadvantaged households (OECD, 2024). The findings corroborate findings from prior research pointing out the strong association between socioeconomic status and the development of leadership skills (Duan et al., 2022), with social class being critical for the emergence and recognition of leadership (Loignon and Kodydek, 2021). `}</p>
      <FocusImage size="large" src={"30-teach_leadership.png"} alt="Disadvantaged students are less assertive than their more privileged peers" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=223" mdxType="FocusImage" />
      <p>{`In summary, leadership training has expanded from an exclusive activity for a few adult students to being more comprehensive and expanding its objectives to prepare the future generation, not just for business but also for social advocacy. It is important to acknowledge the social factors that shape leadership skills and provide equal opportunities for all youth to develop these skills. Finally, more efforts should be invested in analysing the effectiveness of leadership programmes and the different approaches needed at different levels of instruction. `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-learning"
      }}>{`Focuses published in Learning`}</h4>
      <hr></hr>
      <FocusThemeList theme="learning" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;