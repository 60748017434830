
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "New funding sources of scholarships are emerging";
export const lead = "Non-state actors outside of the Global North have been taking on a more prominent role in providing international scholarships.";
export const layout = "focus";
export const article = "finance";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="New funding sources of scholarships are emerging" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=277" mdxType="FocusDetails">
      <h1 {...{
        "id": "new-funding-sources-of-scholarships-are-emerging"
      }}>{`New funding sources of scholarships are emerging`}</h1>
      <p>{`In recent years, more governments outside the DAC members have been supporting national and regional universities and promoting regional student mobility (Kent, 2018). The share of ODA for scholarships from non-DAC countries rose from 1% in 2014 to 9% in 2022. Qatar, Romania, Saudi Arabia and Türkiye have significantly increased their ODA on scholarships, from just USD 29 million in 2013 to USD 450 million in 2022. Since 2018, a large part has gone to Syrian students.`}</p>
      <FocusImage size="small" src={"34-NSA_scholarships.png"} alt="Contributions from non-major industrialized nations are on the rise" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=278" mdxType="FocusImage" />
      <p>{`While international scholarships have historically been funded by governments (Perna et al., 2014), non-state actors outside of the Global North have been taking on a more prominent role. Non-state actors include private foundations, corporate foundations, consortiums of private donors, individual philanthropists, non-governmental organizations that accept donations, private universities, research institutes and religious organizations (Campbell, 2021). There is no established mechanism that systematically gathers and reports information from these actors. Nevertheless, it is possible to trace some developments in this diverse landscape.`}</p>
      <p>{`In sub-Saharan Africa, a small number of large providers accounts for most scholarships (UNESCO, 2020). Non-state actors such as the ABSA Bank in South Africa, Kulika in Uganda, Zawadi Africa Education Fund in Kenya and the Mastercard Foundation offer various opportunities to higher education students (Campbell, 2021). `}</p>
      <p>{`The Mastercard Foundation launched its Scholars Program in 2012 targeting high-achieving youth with demonstrated leadership potential. Scholars are selected by and receive academic and social support from a network of Mastercard partners, including an African leadership academy, non-governmental organizations and universities. The support includes orientation and tutoring; leadership, entrepreneurship and community service training activities; and access to networks, resources (such as counselling) and opportunities for professional preparation (such as internships). Scholars also receive financial support, which varies by partner but generally includes tuition and fees; books, a laptop and other learning materials; accommodation; a living stipend; health insurance; and travel expenses. Between 2012 and 2019, the Scholars Program supported 5,100 scholars at the undergraduate and 1,000 scholars at the graduate level. In 2019, just before COVID-19, the programme further committed USD 850 million for 9,000 university students and also for 26,000 secondary education scholarships. Two thirds of scholars studied in their home country, and nearly all the African alumni who studied in Africa stayed on the continent. Among African scholars who studied abroad, about 50% had returned (Cosentino et al., 2019).`}</p>
      <p>{`In the Arab states, governments drive higher education funding through large programmes, such as the King Abdullah Scholarship Program launched in 2010 in Saudi Arabia, one of the largest overseas scholarship programmes worldwide (Hilal, 2013). Private foundations provide additional funding support, such as the Qaddumi Foundation supporting Palestinian students, the Sheikh Saud bin Saqr Al Qasimi Foundation for Policy Research supporting Emirati students and the Elia Nuqul Foundation supporting Jordanian students (Campbell, 2021). `}</p>
      <p>{`In South Asia, the J. N. Tata Foundation, the Education Future International Scholarship and the Aga Khan Foundation administer programmes according to a new hybrid model, combining scholarships and education loans. In Japan, the Kobayashi International Scholarship Foundation and the Mitsubishi Corporation International Scholarship offer scholarships to outstanding students from Asia coming to Japanese universities. In China, the Schwarzman Scholars is a programme which funds up to 200 students annually – 20% Chinese students, 40% American and the remaining 40% from other countries – to attend a one-year masters at Tsinghua University in Beijing (Campbell, 2021). China has developed into a major scholarship provider for students from developing countries, although calculating the flows is not straightforward.`}</p>
      <p>{`International student mobility among Latin American students is relatively low, with most students heading to the United States (UIS, 2024; IESALC, 2019). In Brazil, Science Without Borders, a flagship scholarship programme launched in 2011 and funded by the Brazilian government, was terminated in 2017 for reasons ranging from students’ low English proficiency to the lack of a strategy for the internationalization of the higher education sector (Nery, 2018; Sá, 2016). Two major actors are the Lemann Foundation and the Brazil Foundation, which offer scholarships to Brazilian students accepted to globally top-ranked universities, with the objective of developing the skills of tomorrow’s leaders in Brazil (Campbell, 2021).`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;