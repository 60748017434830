
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Can solar power close the school electrification gap?";
export const lead = "Large numbers of schools in low- and middle-income countries, especially primary schools, do not have electricity.";
export const layout = "focus";
export const article = "quality";
export const year = 2023;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Can solar power close the school electrification gap?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=306" mdxType="FocusDetails">
      <h1 {...{
        "id": "can-solar-power-close-the-school-electrification-gap"
      }}>{`Can solar power close the school electrification gap?`}</h1>
      <p>{`Large numbers of schools in low- and middle-income countries, especially primary schools, do not have electricity. Investment into large-scale infrastructure for expanding the electricity grid is an obvious but costly solution. Decentralized electric power generation offers an alternative, where the electricity consumed by the school is generated close by. This is not a new concept: diesel-powered generators have long been used for remote hospitals and schools, but they rely on the delivery of heavy fuel and therefore do not create true electricity independence (Jiménez and Lawand, 2000). More recently, PV electricity generation, i.e. solar panels that turn sunlight into an electric current, have created new options for decentralized power generation.`}</p>
      <p>{`The first known deployment of a solar panel to a primary school occurred in Niger in 1968 (Sovacool and Ryan, 2016). In the Brazilian state of Minas Gerais, the large-scale rural school electrification programme Luz no Saber (Light in Knowledge) provided solar power to some 1,000 of the most remote schools between 1995 and 2005 (Diniz et al., 2006).`}</p>
      <p>{`As with many technologies, however, the revolutionary potential of PV electricity generation was triggered not when it first became available, but when it became affordable. This has occurred in the last 10 years. The global average price of solar panels dropped 88% between 2010 and 2021, from USD 0.42 per kWh to USD 0.05 per kWh (IRENA, 2022); prices for large-scale PV projects in Africa are even lower (IRENA, 2016). These price drops are partly due to the higher efficiency of modern PV panels, and partly to technological progress in the manufacturing process. Moreover, auxiliary technologies have matured, such as battery technology for buffering fluctuations and smart algorithms for managing local grids with many independent power inputs.`}</p>
      <p>{`The feasibility of solar solutions for electricity generation is illustrated by their rapid adoption in Africa. Between 2010 and 2019, the number of people in Africa connected to solar minigrids increased 45-fold from only 39,000 to 1,736,000 (IRENA, 2021). Schools lacking electricity are concentrated in climate zones with high solar potential. Among the 31 countries where more than half of all primary schools are without electricity, only three – Congo, Liberia and Nepal – have an average PV power potential below the global average. Among all countries with primary schools that are not electrified, the theoretical PV potential of even the least sunny, Bhutan, is still one third higher than in the Kingdom of the Netherlands, which has the second-highest installed solar PV capacity per capita after Australia (IEA, 2022). Climate change may change current patterns of average cloud coverage and solar panels are less efficient at higher temperatures. However, even taking these factors into account, the solar potential in Africa is not projected to diminish due to climate change (Soares et al., 2019). `}</p>
      <FocusImage size="auto" src={"26-solarenergy.png"} alt="Countries with large school electrification gaps tend to have high potential for solar power generation" href="https://unesdoc.unesco.org/ark:/48223/pf0000385723/PDF/385723eng.pdf.multi.page=307" mdxType="FocusImage" />
      <p>{`However, being located in a favourable climate zone is not enough. Sophisticated technical and economic analyses and optimization are required for the design of a feasible system, taking into account factors such as the site and buildings, solar radiation, load profiles, cost and the lifetime of components (Chatterjee et al., 2018; Endaylalu, 2018). Across Indian states and territories, the percentage of schools with solar power is highest in Chandigarh and Delhi, two of the richest and most urban territories in the country. This suggests that PV technology for school electrification is still driven by local technical and investment capacity potential.`}</p>
      <p>{`Barriers to large-scale electrification of schools include upfront costs, procurement, vandalism and theft (Sovacool and Ryan, 2016). Perhaps due to such challenges, as well as a scarcity of trained technicians for maintenance, some large-scale policies for solar electrification of schools in the early to mid-2010s did not meet their objectives. In Papua New Guinea, a solar lighting programme for rural schools equipped 2,400 classrooms, but a lack of maintenance meant ‘only a handful of units’ were still operating five years later (Sovacool and Ryan, 2016).`}</p>
      <p>{`It may be crucial for the sustainability of projects to ensure that the wider community benefits from solar installations at schools. Part of the problem in Papua New Guinea was that solar panels installed at schools were frequently vandalized or stolen because their exclusive benefit to the school did not align with local understandings of common property rights (Sovacool and Ryan, 2016). A promising – but challenging – approach is to integrate school and community electrification in the form of microgrids (Kirchhoff et al., 2016), local electrical grids with defined electrical boundaries, acting as single and controllable entities. But school electrification can be designed to also benefit households even without microgrids. Under the award-winning Solar Cow initiatives, students take portable batteries to school and charge them during the school day (Chang, 2021). The batteries are sufficient to charge mobile phones and power lights and a radio at home. Pilot programmes in Kenya and the United Republic of Tanzania are benefiting 550 households, and the initiative plans to expand to the Democratic Republic of Congo, Indonesia and Rwanda.`}</p>
      <p>{`Finally, technologies that allow schools to exploit solar energy can provide benefits besides electricity. Direct exposure to solar UV radiation is surprisingly effective at disinfecting water (Schulte, 2011). Sunlight can be captured for heating school buildings through technologies that optimize window area and design against insulation (Liu, 2018). Solar energy can even be used for cooling. Besides electric air conditioning powered by photovoltaics, ‘solar thermal’ or ‘solar thermo-mechanical’ systems use solar energy to directly cool classrooms (Aguilar-Jiménez, 2020). `}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2023 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;