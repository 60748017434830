
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "School infrastructure may be above the minimum standard yet unacceptable and deteriorating";
export const lead = "Policymakers should not consider investment in school infrastructure and the physical conditions for learning as secondary.";
export const layout = "focus";
export const article = "quality";
export const year = 2021;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const ReferenceButton = makeShortcode("ReferenceButton");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="School infrastructure may be above the minimum standard yet unacceptable and deteriorating" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=347" mdxType="FocusDetails">
      <h1 {...{
        "id": "school-infrastructure-may-be-above-the-minimum-standard-yet-unacceptable-and-deteriorating"
      }}>{`School infrastructure may be above the minimum standard yet unacceptable and deteriorating`}</h1>
      <p>{`Policymakers should not consider investment in school infrastructure and the physical conditions for learning as secondary. This is recognized through SDG target 4.a and its global indicator that monitors school facilities, such as electricity, internet, drinking water and sanitation. However, given the variety of facilities and equipment that can affect learning, indicator 4.a.1 cannot offer a comprehensive basis for monitoring the adequacy of physical conditions within schools. Thus other indicators and potential information sources are needed.`}</p>
      <p>{`Surveys that sample schools are one potential source of relevant data. TIMSS surveys head teachers on the extent to which inadequate physical conditions harm instruction quality. In nine countries that participated in the 2019 round, over a third of primary school head teachers reported that inadequate buildings or instruction space strongly affected instruction. In the latter case, the share exceeded 50% in Kuwait, Saudi Arabia and Türkiye `}</p>
      <FocusImage size="medium" src={"5-Infrastructure_1.png"} alt="Instruction quality is hard to maintain in inadequate physical conditions" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=348" mdxType="FocusImage" />
      <p>{`Even among Organisation for Economic Co-operation and Development (OECD) countries, head teachers reported that inadequate buildings or instruction space significantly hindered learning. Over 20% did so in Chile and Slovakia, and over 10% in Finland, France, Hungary, New Zealand and Sweden. Generally, head teachers in high-income countries are more likely to report a negative impact on learning from inadequate buildings and instruction space than from inadequate instructional material and supplies. Among the 46 high- and upper-middle-income countries that participated in the Teaching and Learning International Survey (TALIS), only in 6 did lower secondary school head teachers report that a shortage of materials hindered learning more often (OECD, 2018). Inadequate materials and supplies are cited more often in poorer countries.`}</p>
      <p>{`These figures indicate that many countries fall short of required levels of capital investment and non-recurrent expenditure in schools. Media reports highlight neglected repairs in ‘death trap’ public schools and instances where teachers pay out of pocket to replace outdated learning materials and equipment (Harris, 2018; Peek, 2018; Sedgwick, 2018). In Brazil, school census data showed that instruction space declined in urban schools between 2013 and 2017 while conditions remained unchanged in rural schools. Infrastructure quality was worse in schools of up to 150 students, which accounted for nearly half of students, and in schools with more disadvantaged students (Gomes and Duarte, 2017; UNESCO, 2019).`}</p>
      <p>{`In the United States, it has been estimated that public expenditure would need to increase by 50% to meet the US$146 billion required annually to adequately maintain, upgrade and construct new schools (Filardo, 2016). A government survey in 2019 showed that in 41% of school districts, more than half the schools need to update or replace heating, ventilation and air conditioning. One in eight districts required repairs to improve the structural integrity of at least half their schools (US Government Accountability Office, 2020). The federal government contributes little to no funding for primary and secondary schools, which depend on state and district government funding, resulting in regional inequality (Filardo, 2016).`}</p>
      <p>{`Engineers and other qualified personnel are needed to accurately determine building conditions and needed investment. However, governments generally do not conduct such assessments regularly at the national level, in part due to the complication and costs. In Canada, Ontario’s provincial government hires a team of independent engineers to determine each school’s repair and renewal costs. Results of these assessments are publicly released in the form of a ‘facility condition index’ (FCI), comparing the cost of a school’s repair and renewal needs with the cost of completely rebuilding the school. Data from the 2015 assessment showed that over a third of the province’s schools were found to be in ‘poor’ condition – an FCI between 0.30 and 0.65 (i.e. the repair cost was estimated at up to 65% of the cost of building a completely new school) (Ontario Ministry of Education, 2019; Sachgau, 2016).`}</p>
      <p>{`In the absence of expert reviews, assessments from school staff on building conditions provide valuable information. Although such assessments are not as well suited to gauging buildings’ structural integrity and resilience to natural disasters, evidence suggests they can be effective in diagnosing the conditions most strongly linked to learning outcomes. A study comparing engineering assessments to those by head teachers found the latter much more closely related to the quality of teaching and learning environments (Roberts, 2009).`}</p>
      <p>{`The utility of educator facility assessment has relevance for the monitoring of SDG target 4.a. In particular, government-reported administrative data tend to yield more optimistic assessments of school facilities than those by head teachers. Where administrative data point to all primary schools having access to computers, head teacher assessments reveal shortcomings. Over half of head teachers in France, Kuwait, Oman, Portugal and Slovakia reported that inadequate access to computers moderately or strongly affected learning, yet the countries’ administrative data show all schools having access to computers for pedagogical purposes.`}</p>
      <FocusImage size="medium" src={"5-Infrastructure_2.png"} alt="Head teachers underscore that access to equipment is not enough" href="https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=349" mdxType="FocusImage" />
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <h2 {...{
      "id": "references"
    }}>{`References`}</h2>
    <p>{`The full list of references can be found at this link.`}</p>
    <ReferenceButton url={"https://unesdoc.unesco.org/ark:/48223/pf0000379875/PDF/379875eng.pdf.multi.page=571"} mdxType="ReferenceButton">
  2021/2 GEM Report
    </ReferenceButton>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;