
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "Are school principals’ salaries attractive?";
export const lead = "Financial compensation is an important motivating factor for attracting and retaining talented individuals in school principal positions.";
export const layout = "focus";
export const article = "finance";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="Are school principals’ salaries attractive?" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=300" mdxType="FocusDetails">
      <h1 {...{
        "id": "are-school-principals-salaries-attractive"
      }}>{`Are school principals’ salaries attractive?`}</h1>
      <p>{`Financial compensation is an important motivating factor for attracting and retaining talented individuals in school principal positions. There is no global database that monitors school principals’ salaries that matches SDG indicator 4.c.5, which monitors teacher salaries relative to those of other professions. The best comparable data set of school principals’ remuneration is sourced from the European Union and the OECD (European Commission/EACEA/Eurydice, 2024), which compiles salaries and allowances of full-time, qualified principals in public schools from their member states and a few other European countries.`}</p>
      <p>{`Two types of salary information are available: statutory and actual salaries. Statutory salary information includes additional pay, such as a 13th month and holiday pay, where applicable, and management allowance (Eurydice and OECD, 2021). Actual salary information includes social security and pension scheme contributions paid by the employees but not the employers. Actual salaries tend to be close to the maximum statutory salaries (European Commission/EACEA/Eurydice, 2016).`}</p>
      <p>{`In upper-middle- and high-income countries, principal salaries can vary by up to 11 times between countries and up to 2 times by education level. In primary and upper secondary education, the statutory salary is USD 96 500 and USD 103 000 in the United States and USD 8 600 and USD 11 000 in Albania. In Mexico, preschool principals earn USD 27 500 and secondary school principals close to USD 60 000. In contrast, there are no pay gaps by education level in Latvia, Lithuania, Montenegro, Portugal, or England and Scotland (United Kingdom).`}</p>
      <FocusImage size="large" src={"36-principal_salaries-1.png"} alt="School principal salaries increase with the level of education" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=300" mdxType="FocusImage" />
      <p>{`Education systems seeking to attract talented individuals as principals need to offer competitive salaries relative to other professional fields. Preschool principals’ salaries are lower than those of other professionals in 5 of the 13 countries with data. At the other education levels, principals are paid at least as well as other professionals in all 17 countries with data except Hungary. Salary premiums are at least 1.4 times higher than for other professions at all levels in 7 countries: Australia, Costa Rica, Ireland, Israel, Italy, Portugal and England (United Kingdom), with the highest premium (2.2 times) being offered for upper secondary school principals in England.`}</p>
      <FocusImage size="large" src={"36-principal_salaries-2.png"} alt="Principal salaries are particularly competitive in a few countries" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=301" mdxType="FocusImage" />
      <p>{`Another measure of competitiveness is to compare principals’ salaries to GDP per capita. This comparison varies across different education levels. Although the number of countries with data is not large enough, a pattern emerges for this select group of upper-middle- and high-income countries. Principals’ salaries are, on average, 1.2 times the GDP per capita in pre-primary, 1.4 times in primary, 1.5 times in lower secondary and 1.6 times in upper secondary education. Taking the case of lower secondary education as an example, two-thirds of these countries maintained or decreased salaries by 7% on average in relative terms between 2016 and 2021. `}</p>
      <FocusImage size="small" src={"36-principal_salaries-3.png"} alt="Principals’ salaries mostly align with or outpace economic growth, especially in primary education" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=302" mdxType="FocusImage" />
      <p>{`Among the countries that have maintained or increased principals’ salaries, three stand out for having increased them by at least 20%: Czechia, Italy and Latvia. Italy has introduced major reforms in school leader autonomy which have significantly impacted principal salaries, albeit gradually. A 1997 law and 2001 legislative decree granted school principals greater autonomy, turning them into ‘school managers’ with expanded administrative and financial responsibilities. This reform aligned their salaries with other public administration managers. Since 2001, salaries have steadily increased, driven by collective labour agreements and budget laws. An average rise of 4% from 2019 to 2021 was partly linked to performance evaluations. In 2024, new criteria were introduced to account for school complexity when determining salary brackets (Italy Ministry of Instruction and Merit, 2024).`}</p>
      <p>{`Data collected from middle-income countries show that many offer significantly higher salaries for school principals as a share of GDP per capita. These reflect the relative scarcity of principals’ qualifications in the labour market. However, not all countries offer such a salary and there is much wider variation than in richer countries. In Eswatini and Kenya, principals earn between four and six times the GDP per capita. At the lower end, principals in El Salvador and Pakistan earn well below the GDP per capita. Also observed was the wide range of the salaries from the starting level to the highest level: in Guatemala and Rwanda, salaries start at about 1.5 times and eventually reach 4.6 times the GDP per capita.`}</p>
      <FocusImage size="small" src={"36-principal_salaries-4.png"} alt="Principals receive high relative salaries in some lower-middle-income countries" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=302" mdxType="FocusImage" />
      <p>{`The ratio of principals’ starting salaries to those of experienced teachers is another measure of a country’s approaches to making the position attractive to teachers. In 2022, headteachers earned about 20% more than teachers with 15 years of experience on average in 33 countries, although the gap varied widely by country. In Italy, principals’ starting salary was more than twice as much as that of experienced teachers while in the Kingdom of the Netherlands it was 40% less, making it an exception, alongside Colombia. Analysis of Eurydice data suggests that this relationship has shifted slightly in European countries in recent years. In 2016/17, principals’ starting salaries were on average over 25% more than that of teachers with 15 years of experience but by 2020/21, the ratio had fallen to 20% (European Commission/EACEA/Eurydice, 2022). In Uruguay, in both primary and secondary education, a principal’s salary is about 80% of what a full-time teacher at the highest grade earns, depending on the school’s size, with vice-principals earning even less (ANEP, 2024; INEEd, 2016).`}</p>
      <FocusImage size="large" src={"36-principal_salaries-5.png"} alt="Principals’ starting salaries are about 20% higher on average than those of experienced teachers" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=303" mdxType="FocusImage" />
      <h2 {...{
        "id": "pay-scales-and-incentive-structures-vary"
      }}>{`Pay scales and incentive structures vary`}</h2>
      <p>{`School principals’ salary structure is crucial for understanding their career development. In many countries, these salaries are based on civil servant pay scales. Analysis of 211 education systems for this report shows that about 70% of countries employ principals as public servants, although this share drops to 48% in high-income countries. `}</p>
      <p>{`Principals’ salaries may be determined by central government, local authorities or collective agreements among stakeholders, guided by a legal framework. In centralized systems, principals’ salaries are part of the civil service pay structures. Adjustments in public service salaries, therefore, directly impact school principals, posing a challenge to compensating them adequately for their managerial roles and responsibilities. In decentralized systems, there is often a more tailored and equitable compensation structure that takes into account issues such as the local cost of living. In Finland, salaries are negotiated at the municipal level and can vary based on the municipal budget and specific agreements with local teacher unions (Finland Ministry of Education, 2007; Statistics Finland, 2023). In Germany, responsibility for determining school principals’ salaries lies with the federal states, which allows for regional variations (European Commission/EACEA/Eurydice, 2015). In the United States, the average annual salary for school principals ranges from USD 57,000 in Puerto Rico to USD 149 000 in Washington, DC (United States Bureau of Labor Statistics, 2024).`}</p>
      <p>{`Principals’ pay scales are either separate than those for teachers or shared. When they are shared, adjustments are made to account for principals’ responsibilities. For example, principals may receive an administrative allowance that acknowledges their increased responsibilities without creating a separate structure. Allowances refer to tasks such as management duties, working overtime, providing student counselling, engaging in extracurricular activities, supporting or training other teachers, and fulfilling teacher or tutor responsibilities. This approach simplifies payroll management while recognizing the principal’s additional workload. It also fosters a sense of unity and a cohesive work environment. However, it can blur the distinction between roles and narrow the salary growth and career development opportunities within the scale. Careful management is required, therefore, to ensure principals feel adequately compensated. Separate pay scales ensure that compensation is directly aligned with principals’ specific duties and the complexity of their work. `}</p>
      <p>{`Unified salary systems for principals and teachers are more common in low- and middle-income countries than in high-income countries. In Eswatini, principals and teachers follow the same salary system, but there are differentiated salary structures based on education level. Principals’ salaries are placed higher than the most experienced secondary teachers, for example (World Bank, 2021a). Zambia has nine salary levels, from primary education teachers to permanent secretaries. Primary school principals are positioned at the fifth level, while secondary school principals are at the sixth level, just below local education officials and supervisors (Zambian Observer, 2024). In Peru, the remuneration policy involves grouping head teachers and teachers together, assigning principals to the teacher pay scale with additional allowances ranging from USD 160 to USD 210 for positions involving greater responsibilities (Peru Ministry of Education, 2024). In Europe, only nine countries have adopted a unified scale (European Commission/EACEA/Eurydice, 2022). In Czechia, preschool principals are categorized within the tenth salary category of the teacher pay scale. This placement is higher than that of teachers at the same level but lower than that of teachers in primary and secondary education. School principals are placed in the highest salary category, alongside some teachers who have extensive programme planning responsibilities (OECD, 2022a). `}</p>
      <p>{`Separate pay scales explicitly recognize principals’ unique roles and responsibilities, setting clear boundaries and expectations which are distinct from teaching staff. This method provides clearer career progression and can motivate teachers to aspire to principal positions by offering distinct financial benefits and formal recognition of their leadership roles. In Europe, 23 education systems use separate pay scales for school principals (European Commission/EACEA/Eurydice, 2022). In England (United Kingdom), there are eight levels of school principal pay, explicitly recognizing their leadership and administrative roles (NASUWT, 2023; OECD, 2022a). `}</p>
      <p>{`In a few countries, school principals are not integrated into the government’s payroll system. For example, in Somalia, while half are registered under the public payroll, the remainder receives salaries from private sources or international donors such as the World Bank (IIEP and GPE, 2022).`}</p>
      <p>{`Some studies argue that principals’ salaries are inadequate given their workload and responsibilities (Lee and Mao, 2023). Well-designed compensation packages and appraisal systems play a crucial role in enhancing the appeal of a principalship career (Biasi, 2021). Elements of such a design include regularly updating salaries to maintain competitiveness, ensuring that the pay reflects the responsibilities and challenges of the role, recognizing additional skills in salary scales and implementing pay-for-performance models. Fixed compensation schemes are common. But systems vary by country and within countries. In Chile, the bonus component of wages represents 22% of the principal’s salary in voucher-private schools but only 9% in public schools (Muñoz and Prem, 2022). `}</p>
      <p>{`Principals’ pay scales may reflect factors such as school size and location. In Bhutan, principals can advance from the level of Principal III to Principal I. With each promotion, they receive a higher salary and performance-based incentives, along with the expectation to perform at a higher level with additional roles. For example, school principals with a Principal I ranking may be asked to lead larger schools (Bhutan Ministry of Finance, 2023b; Royal Civil Service Commission, 2017). In Uzbekistan, school principals’ base salary is differentiated based on the size of the school and the position and category levels. For example, as of August 2022, relative to the base salary, school principals received 7% more for schools with 401 to 880 students, 13% more for schools with 881 to 1,600 students, and 20% more for schools with more than 1,600 students (Odilov, 2023). In half of the OECD countries with data, school principals receive additional compensation for working in disadvantaged or remote areas.`}</p>
      <p>{`Some systems design pay scales that give additional money for relevant qualifications and expertise, as well as to encourage principals to stay in their jobs. Allowances are provided for further formal qualifications, successful completion of continuing professional development activities and outstanding performance. Countries often establish qualification programmes for leadership preparation, offering higher salary increments upon completion. In Malaysia, graduates of the National Professional Qualification for Educational Leaders (NPQEL) receive a salary increase upon completion. Initiated in 1979, this programme was initially designed to train senior teachers and experienced administrators for headship roles. NPQEL 2.0 was launched in 2018. It reduces the training period from five months to three months and three weeks. This change aimed to avoid the need for replacement teachers and to reduce costs through hybrid learning (Singh, 2019). Now mandatory for future principals, the restructuring of NPQEL led to an increase in participants, with three intakes per year instead of two (Education Service Commission, 2024; Ng, 2017; Nor and Razak, 2021; Singh, 2019).`}</p>
      <p>{`In contrast, some countries do not automatically offer salary increases for upgrading skills and qualifications. In Eswatini, there are horizontal career steps from teacher to deputy principal to principal and to higher administrative positions. While there is a substantial salary increase when moving to higher steps, the salary increments within each step are minimal (World Bank, 2021b). `}</p>
      <p>{`The obligation of obtaining professional training and qualifications may discourage potential candidates if salaries are not paid during the study. Singapore's Leaders in Education Program provides a model. An initiative by the Ministry of Education in collaboration with the National Institute of Education, this six-month full-time programme is designed to prepare selected vice-principals and ministry officers for school leadership roles. To attract and retain top talent in education leadership, participants receive their full salary during the programme and all fees are covered by the Ministry of Education (Nor and Razak, 2021).`}</p>
      <h2 {...{
        "id": "some-changes-to-principals-salaries-are-part-of-wider-reforms"
      }}>{`Some changes to principals’ salaries are part of wider reforms`}</h2>
      <p>{`Efforts to improve incentives for principals often coincide with broader teacher policy reforms aimed at improving professionalization and educators’ career development. In Bhutan, the 2022 Pay Structure Reform Act introduced a performance-based pay scale in civil servant compensation. Historically, these pay scales were based solely on fixed basic pay, which provided little flexibility or motivation for employees to exceed expectations. The new pay structure incorporates a variable payment component in addition to the basic pay, determined by a set of performance indicators tailored to different roles and responsibilities. For instance, in the education sector, school principals’ performance is assessed based on factors such as student outcomes, school management efficiency and community engagement (Bhutan Ministry of Finance, 2023a, 2023b; Pay Commission, 2023; Pay Structure Reform Bill of Bhutan 2022, 2022). `}</p>
      <p>{`In Kenya, the Teachers Service Commission has introduced significant changes to improve teachers’ and principals’ remuneration (Kenya Gazette Supplement, 2024). These amendments include not only salary increases but also comprehensive financial policies, such as structured salary scales under the 2018 Career Progression Guidelines and the 2017–21 Collective Bargaining Agreement (Teachers Service Commission, 2019). Salaries are negotiated with the Kenya National Union of Teachers and the Kenya Union of Post Primary Education Teachers, with new policies designed to reflect the increased responsibilities of school principals and provide incentives for career advancement (Teachers News, 2024b). Principals have been positioned above senior teachers – a key modification in the 2021–25 Collective Bargaining Agreement – offering financial incentives such as higher basic salaries, substantial allowances and improved pension benefits. These changes were to be implemented in two phases in 2023 and 2024 but the salary increase for 2024 has been delayed in its implementation due to budget cuts (Mwangi, 2024; Kenya Gazette Supplement, 2024; School Updates, 2024; Teachers Updates, 2024). Current debate focuses on reviewing the Career Progression Guidelines for creating a new pathway for senior teachers to transition into management-level leadership positions (Teachers News, 2024a).`}</p>
      <p>{`Beginning in 2007, Peru introduced a series of teacher policy reforms, such as test-based hiring and the elimination of automatic yearly promotions. In 2012, the Teacher Reform Law incorporated a merit-based payment mechanism for teachers and principals (Bruns et al., 2023; Mendoza Choque, 2019). The reform differentiated school principals’ pay scales from those of other senior teachers. It also introduced a nationwide exam for principal qualifications, with additional allowances for those who passed, while incumbent principals who failed were replaced (World Bank, 2015, 2021c). The Teacher Reform Law also established meritocratic appointment standards and improved career incentives for school principals. Implementation began in 2014 and, in 15,000 of 52,000 public schools, school principals were appointed through this process in 2015 (Bruns et al., 2023).`}</p>
      <p>{`Overall, alongside adjustments to salaries in line with inflation to maintain the attractiveness of principals’ positions, countries have adopted various measures to attract candidates, including comprehensive training programmes, qualification schemes and the alignment of job descriptions with those in comparable sectors in the civil service. These efforts underscore the importance of professionalization and career development in enhancing the attractiveness of school leadership roles.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-finance"
      }}>{`Focuses published in Finance`}</h4>
      <hr></hr>
      <FocusThemeList theme="finance" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;