
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const title = "‘Teacher shortages’ is used to describe different problems which require different policies";
export const lead = "‘The term ‘shortage’ is used to describe phenomena with different causes – insufficient supply and insufficient demand.";
export const layout = "focus";
export const article = "quality";
export const year = 2024;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const BackLink = makeShortcode("BackLink");
const Share = makeShortcode("Share");
const FocusDetails = makeShortcode("FocusDetails");
const FocusImage = makeShortcode("FocusImage");
const Aside = makeShortcode("Aside");
const FocusThemeList = makeShortcode("FocusThemeList");
const layoutProps = {
  title,
lead,
layout,
article,
year
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Header mdxType="Header">
  <BackLink name="focusPosts" mdxType="BackLink">All focuses</BackLink>
  <Share message="‘Teacher shortages’ is used to describe different problems which require different policies" disableEmbed mdxType="Share" />
    </Header>
    <FocusDetails url="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=287" mdxType="FocusDetails">
      <h1 {...{
        "id": "teacher-shortages-is-used-to-describe-different-problems-which-require-different-policies"
      }}>{`‘Teacher shortages’ is used to describe different problems which require different policies`}</h1>
      <p>{`Teacher shortages have been gaining increased attention worldwide. UNESCO has projected a need for 44 million additional primary and secondary teachers to meet SDG 4 targets by 2030 (UNESCO, 2024a). But using the term ‘shortage’ to describe phenomena with different causes – insufficient supply and insufficient demand – and in need of different policy responses can be confusing. It would, therefore, be more useful to distinguish between a ‘shortage of teachers’ and a ‘shortage of teaching positions’:`}</p>
      <ul>
        <li parentName="ul">{`Vacancies but no teachers: An insufficient supply of teachers means that there are not enough qualified and interested candidates to fill current vacancies. This can be due to low enrolment in teaching programmes, low uptake of teaching positions or high attrition from the profession.`}</li>
        <li parentName="ul">{`Teachers but no vacancies: An insufficient demand for teachers means that there are not enough teaching vacancies available, usually because of budgetary restrictions, even though more teachers are needed to maintain or improve learning conditions and teachers are available.`}</li>
      </ul>
      <p>{`These two challenges are often conflated because they lead to common outcomes, such as high pupil/teacher ratios, a high share of non-qualified or non-trained teachers, the strong prevalence of teaching ‘out of field’ (i.e. a subject unrelated to the teacher’s specialization), double shifts, and multigrade classes.`}</p>
      <p>{`In upper-middle- and high-income countries, there tends to be a teacher shortage on the supply side, with an insufficient pool of qualified and interested candidates. On average, in middle- and high-income countries, only about 4% of 15-year-olds wanted to become teachers in 2015, ranging from as low as 1% in Canada, Denmark and Latvia to 10% in Thailand, 12% in Tunisia and Viet Nam, and 23% in Algeria (OECD, 2018). Low interest has translated into low and sharply decreasing numbers of entrants into teaching programmes in many high-income countries. In Hungary, which faces teacher shortages in secondary education, the share of new tertiary entrants in the field of education fell from 12% in 2015 to 7% in 2022 (OECD, 2024a, 2024c). In the Kingdom of the Netherlands, it fell from 10% to 7% and in the United Kingdom from 8% to 5% (OECD, 2024c). `}</p>
      <p>{`In Australia, where teacher shortages have reached an unprecedented level, nearly two thirds of teenagers who expressed interest in becoming teachers gave up by the time they turned 23 (Sikora, 2021; Withers, 2024). The number of graduates from initial teacher education declined by 17% in only three years between 2017 and 2020 (Australia Department of Education, 2022). A series of reforms, including scholarships for teacher education programmes, have been introduced to increase the attractiveness of the profession (OECD, 2024a).`}</p>
      <p>{`In low- and lower-middle-income countries, the challenge tends to be a shortage of teaching positions, where in many cases the number of qualified teaching candidates surpasses the number of available teaching positions. In Senegal, over 3,000 candidates successfully passed the competition to become teachers in 2020, but despite the government’s initial commitment to hire all of them, only 2,000 were hired (Faye, 2021). The surplus of qualified teachers available in Senegal contrasts with the fact that around one quarter of primary and lower secondary teachers do not have the minimum required qualifications to teach at those levels. In Congo, the Ministry of Education’s 2022–26 recruitment plan proposed hiring 4,000 teachers per year during the period. But the Ministry of Finance, which decides on the number of vacancies, only authorized 1,500 positions in 2023 (Gouëdard, 2024).`}</p>
      <p>{`Several factors help explain the differences between countries at different income levels in terms of teacher shortages or teacher position shortages. First, the level of qualification required for teaching tends to be higher in richer countries. In most high-income countries, teachers are required to have at least a bachelor’s, and often a master’s, degree to teach in pre-primary, primary and secondary education. Having higher qualifications increases the options available to teachers in other areas. A meta-analysis in high-income countries concludes that teachers with higher qualifications and specializations are more likely to leave the profession (Nguyen et al., 2020). `}</p>
      <FocusImage size="large" src={"35-teacher_shortage.png"} alt="Richer countries often set higher minimum qualifications for teachers" href="https://unesdoc.unesco.org/ark:/48223/pf0000391406/PDF/391406eng.pdf.multi.page=289" mdxType="FocusImage" />
      <p>{`The high level of formal education required in high-income countries contrasts with the declining prestige of the teaching profession. In a survey of over 100 teacher unions, no European or North American country selected teaching as the most respected profession. This was only the case in Argentina, Côte d’Ivoire, Kenya, Lesotho, the Republic of Korea and Sri Lanka (Stromquist, 2018). In the United States, the social status of the teaching profession – including occupational prestige, interest among students, number of entrants and job satisfaction – is at its lowest level in 50 years (Kraft and Lyon, 2024).`}</p>
      <p>{`Meanwhile, low national requirements and weak planning can contribute to a surplus of qualified teachers in poorer countries. In Ethiopia, enrolment in colleges of teacher education (CTE) – the minimum required qualification for primary and lower secondary teachers – is an alternative study path for academically weaker students. At the end of grade 10, students take a national examination that selects those who qualify for the ‘preparatory level’ (grades 11 and 12), which aims at preparing students for higher education. Those who do not pass the examination may enrol in CTEs or in technical and vocational education institutes (Ethiopia Ministry of Education, 2018). Due to the high number of entrants to CTEs, the supply of potential qualified teachers in the country is increasing much faster than demand. In 2019, the number of CTE graduates (86,363) was higher than attrition (10,944) combined with the number of unqualified teachers (52,439). This means that the number of graduates in 2019 alone would be enough to replace the teachers who left, substitute all unqualified teachers and still add 22,980 new members to the teaching force (Ethiopia Ministry of Education, 2020).`}</p>
      <p>{`Other important factors include salary and type of contract. In most high-income countries, teacher salaries are lower than those of similarly educated workers, increasing the opportunity cost of staying in the classroom. In low- and lower-middle-income countries, although not always the case, teachers are more likely to earn salaries that are on par with other professions at similar qualification levels (Bennell, 2023; UNESCO, 2021). Moreover, teachers are often civil servants with stable contracts and other benefits, which may be more valuable in countries with poor safety nets and a high share of informal employment (Barton et al., 2017). `}</p>
      <p>{`The relatively higher administrative and budgetary costs of hiring qualified teachers help explain the insufficient demand in some low-income contexts. In Madagascar, recruitment into the civil service was frozen between 2018 and 2023 due to budgetary constraints, forcing many parents’ associations to hire community teachers locally who tend to be underqualified and underpaid. The share of community teachers rose from 6% in 2015 to 32% in 2021, despite a ban on this type of recruitment by the Ministry of Education since 2014, in an attempt to improve teacher quality (Gouëdard, 2023).`}</p>
      <p>{`Within countries, supply and demand challenges also apply to difficulties staffing specific schools, such as those in rural or disadvantaged areas. In the case of insufficient supply, two different phenomena may be occurring: there is a scarcity of teachers at the system level or teachers do not take positions in certain schools even if the alternative is to not teach at all. In Ecuador’s centralized teacher selection process, prospective teachers apply for up to five school vacancies and are assigned to one of them by an algorithm. Because schools in favourable locations receive too many applications and disadvantaged schools too few, at the end of the process, a large number of vacancies remain unfilled and a large number of candidates – 56% of them in 2016 – are not hired at all (Ajzenman et al., 2021). `}</p>
      <p>{`In decentralized systems, shortages may be the result of different policy responses. In Brazil, the states of Pará and Rio Grande do Sul have around the same number of students, but Rio Grande do Sul – which has double the gross domestic product per capita – has twice as many teachers (IBGE, 2023; INEP, 2024). There is a sufficient supply of teachers. The field of education is the most common bachelor’s degree in Brazil and both states counted about 7,700 education graduates each in 2022 (INEP, 2023; Pinto dos Santos, 2022). But there is a difference in the availability of teaching positions. In 2018, the government of Pará conducted a public selection process to fill 2,112 open teaching positions (State of Para Government, 2018). However, the government continuously postponed the appointment of over one quarter of the approved candidates and only finalized hirings in 2022 (Menezes, 2020; State of Para Government, 2022; Tapajos de Fato, 2021). Meanwhile, Rio Grande do Sul opened and filled 1,500 teaching positions in 2023 and has planned to open another 3,000 vacancies in 2024 (State of Rio Grande do Sul Government, 2024a, 2024b).`}</p>
      <p>{`Countries also face teacher shortages for subject areas. For example, a lack of science, technology, engineering and mathematics teachers in many high-income countries can be traced to insufficient supply. In England, United Kingdom, entry into initial teacher training is only 17% of the target number for physics and 30% for computing (Department for Education, 2019). In the United States, there were over 30,000 vacancies for physics teachers in 2019 but only some 6,000 physics majors (Foresman, 2019). But other systems face a demand challenge. In countries where school funding is tied to the pupil/teacher ratio, it is not always possible, especially in small schools, to have appropriately trained teachers covering the full spectrum of classes needed, leading to a high incidence of teaching out of field (Hobbs and Porsch, 2021). `}</p>
      <p>{`Different types of shortages require different policy responses. A lack of interested teachers calls for policies related to increasing the attractiveness of the teaching profession and improving retention rates. A lack of teaching positions calls for better financing and planning. In either case, better understanding the problem can help governments better address it.`}</p>
    </FocusDetails>
    <Aside mobilePlacement="bottom" mdxType="Aside">
      <h4 {...{
        "id": "focuses-published-in-quality"
      }}>{`Focuses published in Quality`}</h4>
      <hr></hr>
      <FocusThemeList theme="quality" mdxType="FocusThemeList" />
    </Aside>
    <p>{`  2024/5 GEM Report`}</p>





    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;